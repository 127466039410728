import React, { useRef, useState } from 'react';
import { motion } from 'framer-motion';
import './Grid.css';
import Card from '../Card/Card';
import { GridSnippetLeanBody, GridSnippetFatBody } from '../../snippets/GridSnippet/GridSnippet';

const Grid = () => {
  const snippetContainerRef = useRef(null);
  const [showScanning, setShowScanning] = useState(false);

  const copySnippet = () => {
    if (snippetContainerRef.current) {
      const range = document.createRange();
      range.selectNode(snippetContainerRef.current);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();

      setShowScanning(true);
      setTimeout(() => setShowScanning(false), 1000);
    }
  };
  const colorSnipContRef = useRef(null);
  const [showColorScanning, setColorScan] = useState(false)
  const copyColorSnippet = () => {
    if (colorSnipContRef.current) {
      const range = document.createRange();
      range.selectNode(colorSnipContRef.current);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();

      setColorScan(true);
      setTimeout(() => setColorScan(false), 1000);
    }
  };
  return (
    <motion.div
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -10, opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <div className="Grid" >
        <div className="carve-lean-body" style={{ maxWidth: '80rem' }}>

          <div className="heading-xlarge font-medium"><u>container</u> <br></br>
            <h6>now it is a lean body </h6>
          </div>
          <div className="carve-line">
            <div className="box-12">

            </div>
          </div>
          <div className="carve-line">
            <div className="box-6">

            </div>
            <div className="box-6">

            </div>
            <div className="box-6">

            </div>
          </div>
          <div className="carve-line">
            <div className="box-3">

            </div>
            <div className="box-3">

            </div>
            <div className="box-3">

            </div>
            <div className="box-3">

            </div>
          </div>
          <div className="carve-line">
            <div className="box-4">

            </div>
            <div className="box-4">

            </div>
            <div className="box-4">

            </div>
            <div className="box-4">

            </div>
          </div>
          <div className="carve-line">
            <div className="box-2 box-sm-4 box-md-6 box-lg-12">

            </div>
            <div className="box-2 box-sm-4 box-md-6 box-lg-12">

            </div>
            <div className="box-2 box-sm-4 box-md-6 box-lg-12">

            </div>
            <div className="box-2 box-sm-4 box-md-6 box-lg-12">

            </div>
          </div>
        </div><br></br><br></br>
      </div>
      <div className={`center-container ${showScanning ? 'scanning' : ''}`} style={{ maxWidth: '80rem', margin: 'auto' }}>
        <div className='snippet' ref={snippetContainerRef} >
          <GridSnippetLeanBody />
        </div>
        <button onClick={copySnippet}>
          <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#000000" viewBox="0 0 256 256"><path d="M216,40V168H168V88H88V40Z" opacity="0.2"></path><path d="M216,32H88a8,8,0,0,0-8,8V80H40a8,8,0,0,0-8,8V216a8,8,0,0,0,8,8H168a8,8,0,0,0,8-8V176h40a8,8,0,0,0,8-8V40A8,8,0,0,0,216,32ZM160,208H48V96H160Zm48-48H176V88a8,8,0,0,0-8-8H96V48H208Z"></path></svg>
        </button>
      </div>
      <div className="Grid" style={{ marginTop: '5rem' }}>
        <div className="carve-fat-body">

          <div className="heading-xlarge font-medium"><u>container fluid</u> <br></br>
            <h6>now it is a fat body </h6>
          </div>
          <div className="carve-line">
            <div className="box-12">

            </div>
          </div>
          <div className="carve-line">
            <div className="box-6">

            </div>
            <div className="box-6">

            </div>
            <div className="box-6">

            </div>
          </div>
          <div className="carve-line">
            <div className="box-3">

            </div>
            <div className="box-3">

            </div>
            <div className="box-3">

            </div>
            <div className="box-3">

            </div>
          </div>
          <div className="carve-line">
            <div className="box-4">

            </div>
            <div className="box-4">

            </div>
            <div className="box-4">

            </div>
            <div className="box-4">

            </div>
          </div>
          <div className="carve-line">
            <div className="box-2 box-sm-4 box-md-6 box-lg-12">

            </div>
            <div className="box-2 box-sm-4 box-md-6 box-lg-12">

            </div>
            <div className="box-2 box-sm-4 box-md-6 box-lg-12">

            </div>
            <div className="box-2 box-sm-4 box-md-6 box-lg-12">

            </div>
          </div>
        </div><br></br><br></br>
      </div>
      <div className={`center-container ${showColorScanning ? 'scanning' : ''}`}>
        <div className='snippet' ref={colorSnipContRef}>
          <GridSnippetFatBody />
        </div>
        <button onClick={copyColorSnippet}>
          <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#000000" viewBox="0 0 256 256"><path d="M216,40V168H168V88H88V40Z" opacity="0.2"></path><path d="M216,32H88a8,8,0,0,0-8,8V80H40a8,8,0,0,0-8,8V216a8,8,0,0,0,8,8H168a8,8,0,0,0,8-8V176h40a8,8,0,0,0,8-8V40A8,8,0,0,0,216,32ZM160,208H48V96H160Zm48-48H176V88a8,8,0,0,0-8-8H96V48H208Z"></path></svg>
        </button>
      </div>
    </motion.div>
  );
}

const GridCard = () => {
  return (
    <Card
      CardTitle='Grid'
      CardBody={<Grid />} />
  );
}
Grid.propTypes = {};

Grid.defaultProps = {};

export default GridCard;
