import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import Card from "../Card/Card";
import "./Spa.css";
import {
  CodeSnippetBody,
  ScriptSnippet,
  LinkSnippet,
  RoutingSnippet,
  RouteSnippet
} from "../../snippets/SpaSnippet/Spa-snippet";
const HtmlText = ({ text }) => <>{text}</>;

const Spa = () => {
  const handleLibrary = (data) => {
    let cssFilePath = "";
    let filename = "";
    if (data === 1) {
      cssFilePath = "/spa/v1.0.0/src.zip";
      filename = "src.zip";
    } else {
      cssFilePath = "/spa/v1.0.0/demo.zip";
      filename = "demo.zip";
    }
    const link = document.createElement("a");
    link.href = cssFilePath;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <motion.div
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -10, opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <div className="spa-section">
        <div class="container">
          {/* <div class="section-heads">
            <h2 class="heading-xlarge carve-text-middle">Single Page Application</h2> <br />
            <sup>(from Scratch)</sup>
          </div> */}
          <p>
            Welcome to the guide for creating a Single Page Application (SPA)
            from scratch. Follow the steps below to set up and run your SPA
            using the provided library.
          </p>
          <h2>
            <u>Steps to Follow</u>
          </h2>
          <div class="section">
            <h3>Step 1: Download the Library</h3>
            <p>
              Download the library from the link below and include it in your
              project files.
            </p>
            <button
              class="button carve-text-middle carve-button-main"
              onClick={() => handleLibrary(1)}
            >
              Download Library
            </button>
          </div>
          <div class="section">
            <h3>
              Step 2: Define <code>index.html</code> and Include the Library
            </h3>
            <p>
              Create an <code>index.html</code> file with the following
              structure:
            </p>

            <CodeSnippetBody />

            <p>Include the necessary libraries:</p>
            <ScriptSnippet />
            <p>
              <strong>Note:</strong> The jQuery library is required for the
              provided libraries to function correctly.
            </p>
          </div>

          <div class="section">
            <h3>Step 3: Define Routes</h3>
            <p>
              Define the routes in <code>routes.js</code>. This file should
              contain an object array with the path and the corresponding source
              file.
            </p>
            <RouteSnippet />
            <p>
              In this example, the path <code>/</code> and <code>/login</code>{" "}
              will load the <code>login.html</code> file from the{" "}
              <code>pages</code> folder.
            </p>
          </div>

          <div class="section">
            <h3>Step 4: Use Navigation</h3>
            <p>
              You can navigate between pages using the <code>&lt;a/&gt;</code>{" "}
              tag by specifying the <code>href</code>
              attribute with the defined paths from <code>routes.js</code>.
            </p>
            <LinkSnippet />
            <p>
              Alternatively, you can use the <code>routing()</code> JavaScript
              function, which takes the path as a parameter to navigate
              programmatically.
            </p>
            <RoutingSnippet />
          </div>
          <div class="section">
            <h2>
              <strong>Full Working Demo</strong>{" "}
            </h2>
            <p>Get a full working demo from the link below.</p>
            <button
              class="button carve-text-middle carve-button-main"
              onClick={() => handleLibrary()}
            >
              Download Full Demo
            </button>
          </div>
          <div class="section">
            <h2>
              {" "}
              <strong>Summary</strong>{" "}
            </h2>
            <p>
              By following these steps, you will set up a basic single page
              application. Ensure all paths and files are correctly referenced,
              and your application should function seamlessly. For any issues or
              further assistance, refer to the full documentation or contact
              support.
            </p>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const SpaCard = () => {
  return (
    <Card
      CardTitle={<HtmlText text="Single Page Application Library" />}
      CardBody={<Spa />}
    />
  );
};

export default SpaCard;
