// src/Home.js
import React, { useState, useRef } from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
const Home = () => {
  const handleDownload = () => {
    const cssFilePath = "/v1.1.0.zip";
    const link = document.createElement('a');
    link.href = cssFilePath;
    link.download = "v1.1.0.zip";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const initialCdnUrl = "https://carve.enminvithaigal.in/v1.1.0/css/carve.css";
  const initialCdnUrljs = "https://carve.enminvithaigal.in/v1.1.0/js/carve.js";
  const [cdnUrl] = useState(initialCdnUrl);
  const [cdnUrlJs] = useState(initialCdnUrljs);
  const cssInputRef = useRef(null);
  const jsInputRef = useRef(null);

  const handleCopyClick = (ref) => {
    if (ref.current) {
      ref.current.select();
      document.execCommand("copy");
    }
  };

  return (
    <div className="home-section">
      <motion.div
        initial={{ x: 10, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: -10, opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        <div className="Home">
          <div className="carve-h-app-container">
            <section className="carve-h-cta">
              <h2>Start Building with us</h2>
              <p>
                Experience the simplicity and power of our CSS library. Get
                started today!
              </p>
              <h1>Carve SPA Library</h1>
              <br />
              <br />
              <Link to="/spa">
                <button className="carve-button-main spa">
                  Go to SPA Library
                </button>
              </Link>
              <br />
            </section>

            <section className="carve-h-hero">
              <h1>Carve CSS Library</h1> <br />
              <Link to="/accordion">
                <u>Get Started</u>
              </Link>
              <br />
              <br />
              <h5>
                A lightweight and customizable library for building modern web
                applications.
              </h5>{" "}
              <br />
              <br />
              <button className="carve-button-main" onClick={handleDownload}>
                Download (LTS)
              </button>{" "}
              <br />
              <br />
              (or)
            </section>
            <div className="input-field mandate">
              <input
                type="text"
                required=""
                id="cssValue"
                value={cdnUrl}
                readOnly
                ref={cssInputRef}
              />
              <label
                htmlFor="cssValue"
                style={{
                  top: "-0.7rem",
                  fontSize: "90%",
                  background: "var(--day)",
                  padding: "0rem 1.5rem",
                }}
              >
                CDN for CSS (LTS)
              </label>
              <button
                onClick={() => handleCopyClick(cssInputRef)}
                style={{
                  boxShadow: "none",
                  position: "absolute",
                  right: "0rem",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                <svg
                  className="icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  fill="#000000"
                  viewBox="0 0 256 256"
                >
                  <path d="M216,40V168H168V88H88V40Z" opacity="0.2"></path>
                  <path d="M216,32H88a8,8,0,0,0-8,8V80H40a8,8,0,0,0-8,8V216a8,8,0,0,0,8,8H168a8,8,0,0,0,8-8V176h40a8,8,0,0,0,8-8V40A8,8,0,0,0,216,32ZM160,208H48V96H160Zm48-48H176V88a8,8,0,0,0-8-8H96V48H208Z"></path>
                </svg>
              </button>
            </div>
            <div className="input-field mandate">
              <input
                type="text"
                required=""
                id="jsValue"
                value={cdnUrlJs}
                readOnly
                ref={jsInputRef}
              />
              <label
                htmlFor="jsValue"
                style={{
                  top: "-0.7rem",
                  fontSize: "90%",
                  background: "var(--day)",
                  padding: "0rem 1.5rem",
                }}
              >
                CDN for JS
              </label>
              <button
                onClick={() => handleCopyClick(jsInputRef)}
                style={{
                  boxShadow: "none",
                  position: "absolute",
                  right: "0rem",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                <svg
                  className="icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  fill="#000000"
                  viewBox="0 0 256 256"
                >
                  <path d="M216,40V168H168V88H88V40Z" opacity="0.2"></path>
                  <path d="M216,32H88a8,8,0,0,0-8,8V80H40a8,8,0,0,0-8,8V216a8,8,0,0,0,8,8H168a8,8,0,0,0,8-8V176h40a8,8,0,0,0,8-8V40A8,8,0,0,0,216,32ZM160,208H48V96H160Zm48-48H176V88a8,8,0,0,0-8-8H96V48H208Z"></path>
                </svg>
              </button>
            </div>

            <section className="carve-h-features">
              <div className="carve-card uti-card">
                <div className="carve-card-head">Responsive Design</div>
                <div className="carve-card-body">
                  Build responsive and mobile-friendly websites with ease.
                </div>
              </div>
              <div className="carve-card uti-card">
                <div className="carve-card-head">Extensible Components</div>
                <div className="carve-card-body">
                  Use a variety of components to create rich user interfaces.
                </div>
              </div>
              <div className="carve-card uti-card">
                <div className="carve-card-head">Easy to Customize</div>
                <div className="carve-card-body">
                  Customize the look and feel to match your brand's identity.
                </div>
              </div>
            </section>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

Home.propTypes = {};

Home.defaultProps = {};

export default Home;
