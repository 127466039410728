import React from 'react';
import './InputSnippet.css';

const InputSnippet = () => (
 <>
  <pre>
    <code>
      <span className='cn'>&lt;div</span>
      <span className='cln'> class="</span>
      <span className='cn'>input-field baseline mandate</span>
      <span className='cln'>"</span>&gt; <br />
      &nbsp;<span className='cn'> &lt;input</span>
      <span className='cln'> type="</span>
      <span className='cn'>text</span>
      <span className='cln'>"</span><span className='cln'> required</span> 
      <span className='cln'> id="</span> 
      <span className='cn'>value1</span><span className='cln'>"</span>&gt; <br /> <br />
      &nbsp;<span className='cn'> &lt;label</span>
        <span className='cln'> for="</span>
      <span className='cn'>value1</span>
      <span className='cln'>"</span>&gt; Enter the value  
      <span className='cn'>&lt;/label&gt;</span>
      <br />
      <span className='cn'>&lt;/div&gt;</span>
    </code>
  </pre>
 </>
);



export  {InputSnippet};
