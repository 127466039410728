import React, { useRef, useState } from 'react';

import { motion } from "framer-motion"
import './Button.css';
import Card from '../Card/Card';
import CodeSnippet from '../CodeSnippet/CodeSnippet';
const LeftArrow = () => <>&lt;</>;
const RightArrow = () => <>&gt;</>;
const HtmlText = ({ text }) => <>{text}</>;
const ClassTitle = ({ className }) => <>{className}</>;

const Buttons = () => {

  const snippetContainerRef = useRef(null);
  const [showScanning, setShowScanning] = useState(false);

  const copySnippet = () => {
    if (snippetContainerRef.current) {
      const range = document.createRange();
      range.selectNode(snippetContainerRef.current);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();

      setShowScanning(true);
      setTimeout(() => setShowScanning(false), 1000);
    }
  };

  return (
    <>
      <div className="Button">
        <div className="carve-button-group">
          <button className="carve-button-main">button main</button>
          <button className="carve-button-vice">button vice</button>
          <button className="carve-button-pill carve-button-main">button main button pill</button>
          <button className="carve-button-stroke stroke-main">button stroke</button>
          <button className="carve-button-stroke carve-button-pill stroke-main">button stroke button pill</button>
          <button className="button carve-button-fab" aria-label="fab-button">
            <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 256 256"><path d="M228,128a12,12,0,0,1-12,12H140v76a12,12,0,0,1-24,0V140H40a12,12,0,0,1,0-24h76V40a12,12,0,0,1,24,0v76h76A12,12,0,0,1,228,128Z"></path></svg>
          </button>
        </div>
      </div>
      <div className={`center-container ${showScanning ? 'scanning' : ''}`}>
        <div className='snippet' ref={snippetContainerRef}>
          <CodeSnippet
            LeftArrow={<LeftArrow />}
            RightArrow={<RightArrow />}
            ClassTitle={<ClassTitle className="carve-button-main" />}
            HtmlText={<HtmlText text="button main" />}
          />
          <CodeSnippet
            LeftArrow={<LeftArrow />}
            RightArrow={<RightArrow />}
            ClassTitle={<ClassTitle className="carve-button-main carve-button-pill" />}
            HtmlText={<HtmlText text="button main button pill" />}
          />
          <CodeSnippet
            LeftArrow={<LeftArrow />}
            RightArrow={<RightArrow />}
            ClassTitle={<ClassTitle className="carve-button-stroke" />}
            HtmlText={<HtmlText text="button stroke" />}
          />
          <CodeSnippet
            LeftArrow={<LeftArrow />}
            RightArrow={<RightArrow />}
            ClassTitle={<ClassTitle className="carve-button-stroke carve-button-pill" />}
            HtmlText={<HtmlText text="button stroke carve-button-pill" />}
          />
          <CodeSnippet
            LeftArrow={<LeftArrow />}
            RightArrow={<RightArrow />}
            ClassTitle={<ClassTitle className="carve-button-vice" />}
            HtmlText={<HtmlText text="carve-button-vice" />}
          />
          <CodeSnippet
            LeftArrow={<LeftArrow />}
            RightArrow={<RightArrow />}
            ClassTitle={<ClassTitle className="carve-button-fab" />}
            HtmlText={<HtmlText text="carve-button-fab" />}
          />
        </div>
        <button onClick={copySnippet}>
          <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#000000" viewBox="0 0 256 256"><path d="M216,40V168H168V88H88V40Z" opacity="0.2"></path><path d="M216,32H88a8,8,0,0,0-8,8V80H40a8,8,0,0,0-8,8V216a8,8,0,0,0,8,8H168a8,8,0,0,0,8-8V176h40a8,8,0,0,0,8-8V40A8,8,0,0,0,216,32ZM160,208H48V96H160Zm48-48H176V88a8,8,0,0,0-8-8H96V48H208Z"></path></svg>
        </button>
       
      </div>
    </>
  )
}
const Button = () => {
  const generateCard = (key) => (
    <motion.div
      key={key}
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -10, opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <Card
        CardTitle={<HtmlText text="Buttons" />}
        CardBody={<Buttons />}
      />
    </motion.div>
  );

  const cards = Array.from({ length: 1 }, (_, index) => generateCard(index));

  return <>{cards}</>;
};


Button.propTypes = {};

Button.defaultProps = {};

export default Button;
