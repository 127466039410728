import React, { useRef, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import './Tab.css';
import Card from './../Card/Card';
import TabSnippet from '../../snippets/TabSnippet/TabSnippet';

const Tab = () => {
  useEffect(() => {
    // Your JavaScript logic goes here
    var rsMenu, rsContent, scrollTabWidth, navItem, navItemLength, tabIndicator, tabHeader;
    rsMenu = document.querySelectorAll('.carve-tab-menu');
    rsContent = document.querySelectorAll('.carve-tab-content');

    rsMenu.forEach((tabHead, index) => {
      tabHead.addEventListener('click', () => {
        rsContent.forEach((tabBody) => {
          tabBody.classList.remove('active');
        });
        rsMenu.forEach((tab) => {
          tab.classList.remove('active');
        });
        rsMenu[index].classList.add('active');
        rsContent[index].classList.add('active');
      });
    });

    tabHeader = document.querySelector(".carve-tabs-head");
    if (tabHeader) {
      scrollTabWidth = tabHeader.width;
      navItem = tabHeader.getElementsByClassName("carve-tab-menu");
      navItemLength = navItem.length;
      tabIndicator = document.getElementById("tabIndicator");
      tabIndicator.style.width = navItem[0].offsetWidth + "px";
    }

    for (let i = 0; i < navItemLength; i++) {
      navItem[i].addEventListener("click", function () {
        var current = document.getElementsByClassName("active");
        current[0].className = current[0].className.replace(" active", "");
        this.className += " active";
        tabIndicator.style.left = navItem[i].offsetLeft + "px";
        tabIndicator.style.width = navItem[i].offsetWidth + "px";

        var offLeft = this.offsetLeft;
        var nvWidth = this.offsetWidth;
        if (offLeft > current[0].offsetLeft) {
          tabHeader.scrollLeft = offLeft + nvWidth;
        } else {
          tabHeader.scrollLeft = offLeft - nvWidth;
        }
      });
    }
  }, []);
  const snippetContainerRef = useRef(null);
  const [showScanning, setShowScanning] = useState(false);

  const copySnippet = () => {
    if (snippetContainerRef.current) {
      const range = document.createRange();
      range.selectNode(snippetContainerRef.current);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();

      setShowScanning(true);
      setTimeout(() => setShowScanning(false), 1000);
    }
  };
  return (
    <motion.div
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -10, opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <div className="carve-tab">
        <div className="carve-tabs-head">
          <div className="carve-menu-group">
            <div className="carve-tab-menu active">op 1</div>
            <div className="carve-tab-menu">Option 2</div>
            <div className="carve-tab-menu">Option 3</div>
            <div className="carve-tab-menu">Option 4</div>
            <div className="carve-tab-menu">Option Option 5</div>
          </div>
          <div className="tab-indicator" id="tabIndicator"></div>
        </div>
        <div className="carve-tabs-body">
          <div className="carve-tab-content active">
            1.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
          </div>
          <div className="carve-tab-content">
            2.It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
          </div>
          <div className="carve-tab-content">
            3.Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, 
          </div>
          <div className="carve-tab-content">
            4.The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.


          </div>
          <div className="carve-tab-content">
            5.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. 
          </div>
        </div>
      </div>
      <div className={`center-container ${showScanning ? 'scanning' : ''}`}>
        <div className='snippet' ref={snippetContainerRef}>
          <TabSnippet />
        </div>
        <button onClick={copySnippet}>
          <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#000000" viewBox="0 0 256 256"><path d="M216,40V168H168V88H88V40Z" opacity="0.2"></path><path d="M216,32H88a8,8,0,0,0-8,8V80H40a8,8,0,0,0-8,8V216a8,8,0,0,0,8,8H168a8,8,0,0,0,8-8V176h40a8,8,0,0,0,8-8V40A8,8,0,0,0,216,32ZM160,208H48V96H160Zm48-48H176V88a8,8,0,0,0-8-8H96V48H208Z"></path></svg>
        </button>
        
      </div>
    </motion.div>
  );
}

const TabsCard = () => {
  return (
    <Card
      CardTitle='Tabs'
      CardBody={<Tab />}
    />
  );
}

export default TabsCard;