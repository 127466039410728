import React from 'react';

import './BadgeSnippet.css';

const BadgeSnippet = ({ LeftArrow, RightArrow, ClassTitle, HtmlText }) => (
  <>
    <div className='code-snippet'>
      <pre>
        <code>
          <span className='cn'>{LeftArrow}span</span> <span className='cln'>class="</span><span className='cn'>{ClassTitle}</span><span className='cln'>"</span>
          {RightArrow}{HtmlText} <span className='cn'>{LeftArrow}/span{RightArrow}</span>
        </code>
      </pre>
    </div>
  </>
);

BadgeSnippet.propTypes = {};

BadgeSnippet.defaultProps = {};

export default BadgeSnippet;
