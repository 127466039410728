// Main.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Accordion from '../../components/Accordion/Accordion';
import Badge from '../../components/Badge/Badge';
import Button from '../../components/Button/Button';
import Checkbox from '../../components/Checkbox/Checkbox';
import Chip from '../../components/Chip/Chip';
import Home from '../Home/Home';
import Input from '../../components/Input/Input';
import Radio from '../../components/Radio/Radio';
import Select from '../../components/Select/Select';
import Sidebar from '../../components/Sidebar/Sidebar';
import SoleCard from '../../components/SoleCard/SoleCard';
import Switch from '../../components/Switch/Switch';
import Tab from '../../components/Tab/Tab';
import Background from '../../components/Background/Background';
import Grid from '../../components/Grid/Grid';
import Position from '../../components/Position/Position'
import Width from '../../components/Width/Width';
import Space from '../../components/space/space';
import Text from '../../components/Text/Text';
import Spa from '../../components/Spa/Spa';
import './Main.css';

const Main = () => (
  <div className="Main">
    <Sidebar />
    <div className='main-section'>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/accordion' element={<Accordion />} />
        <Route path='/badge' element={<Badge />} />
        <Route path='/button' element={<Button />} />
        <Route path='/checkbox' element={<Checkbox />} />
        <Route path='/chip' element={<Chip />} />
        <Route path='/input' element={<Input />} />
        <Route path='/radio' element={<Radio />} />
        <Route path='/select' element={<Select />} />
        <Route path='/solecard' element={<SoleCard />} />
        <Route path='/switch' element={<Switch />} />
        <Route path='/tab' element={<Tab />} />
        <Route path='/colors' element={<Background />} />
        <Route path='/grid' element={<Grid />} />
        <Route path='/position' element={<Position />} />
        <Route path='/size' element={<Width />} />
        <Route path='/space' element={<Space />} />
        <Route path='/text' element={<Text />} />
        <Route path='/spa' element={<Spa />} />
      </Routes>
    </div>
  </div>
);

export default Main;
