import React, { useRef, useState } from 'react';
import { motion } from 'framer-motion';
import './Chip.css';
import Card from '../Card/Card';
import { ChipSnippet, CloseIconChip, CloseIconChipLeft } from '../../snippets/ChipSnippet/ChipSnippet';

const LeftArrow = () => <>&lt;</>;
const RightArrow = () => <>&gt;</>;
const HtmlText = ({ text }) => <>{text}</>;
const ClassTitle = ({ className }) => <>{className}</>;
const CardTitle = ({ text }) => <>{text}</>
const Chiptext = () => {
  return (
    <>close icon right</>
  )
};
const Chiptext2 = () => {
  return (
    <>close icon left</>
  )
};
const CloseIcon = () => {
  return (
    <>
      <svg className="icon icon-close" width="15" height="15" strokeWidth="15px" fill="currentColor" viewBox="0 0 256 256"><path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path></svg>
    </>
  )
};
const Chip = () => {
  const snippetContainerRef = useRef(null);
  const [showScanning, setShowScanning] = useState(false);

  const copySnippet = () => {
    if (snippetContainerRef.current) {
      const range = document.createRange();
      range.selectNode(snippetContainerRef.current);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();

      setShowScanning(true);
      setTimeout(() => setShowScanning(false), 1000);
    }
  };

  return (

  <motion.div
    initial={{ y: 10, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    exit={{ y: -10, opacity: 0 }}
    transition={{ duration: 0.2 }}
  >
      <div className="carve-chips-group">
        <div className="carve-chips">normal chip</div>
        <div className="carve-chips fill chip-remove close-right">
        {CloseIcon()}
        {Chiptext()}
      </div>
        <div className="carve-chips chip-remove">
        {CloseIcon()}
        {Chiptext2()}
      </div>
    </div>
      <div className={`center-container ${showScanning ? 'scanning' : ''}`}>
        <div className='snippet' ref={snippetContainerRef}>
          <ChipSnippet
            LeftArrow={<LeftArrow />}
            RightArrow={<RightArrow />}
            ClassTitle={<ClassTitle className='carve-chips' />}
            HtmlText={<HtmlText text='This is the chip' />}
          />
          <CloseIconChip
            LeftArrow="&lt;"
            RightArrow="&gt;"
            ClassTitle="carve-chips"
            HtmlText='&times;'
            hasCloseIcon
          />
          <CloseIconChipLeft
            LeftArrow="&lt;"
            RightArrow="&gt;"
            ClassTitle="carve-chips"
            HtmlText='&times;'
            hasCloseIcon
          />
        </div>
        <button onClick={copySnippet}>
          <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#000000" viewBox="0 0 256 256"><path d="M216,40V168H168V88H88V40Z" opacity="0.2"></path><path d="M216,32H88a8,8,0,0,0-8,8V80H40a8,8,0,0,0-8,8V216a8,8,0,0,0,8,8H168a8,8,0,0,0,8-8V176h40a8,8,0,0,0,8-8V40A8,8,0,0,0,216,32ZM160,208H48V96H160Zm48-48H176V88a8,8,0,0,0-8-8H96V48H208Z"></path></svg>
        </button>
        
      </div>
  </motion.div>
  )
};
const ChipCard = () => {
  return (
    <Card
      CardTitle={<CardTitle text='Chips' />}
      CardBody={<Chip />}
    />
  );
}
export default ChipCard;