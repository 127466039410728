import React, { useRef, useState } from 'react';
import { motion } from 'framer-motion';
import './Position.css';
import Card from '../Card/Card';

const PositionSnippet = () => {
  const snippetContainerRef = useRef(null);
  const [showScanning, setShowScanning] = useState(false);

  const copySnippet = () => {
    if (snippetContainerRef.current) {
      const range = document.createRange();
      range.selectNode(snippetContainerRef.current);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();

      setShowScanning(true);
      setTimeout(() => setShowScanning(false), 1000);
    }
  };
  return (
    <>
      <pre>
        <div className={`center-container ${showScanning ? 'scanning' : ''}`}>
          <div className='snippet' ref={snippetContainerRef}>
            <code>
              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-spot-relative<span className='cln'>"</span><span className='cn'>&gt;</span>
              position relative  element
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>
              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-spot-absolute<span className='cln'>"</span><span className='cn'>&gt;</span>
              position absolute element
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>
              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-spot-freeze<span className='cln'>"</span><span className='cn'>&gt;</span>
              position fixed element
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>
              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-spot-hange<span className='cln'>"</span><span className='cn'>&gt;</span>
              position hang element
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>
            </code>
          </div>
          <button onClick={copySnippet}>
            <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#000000" viewBox="0 0 256 256"><path d="M216,40V168H168V88H88V40Z" opacity="0.2"></path><path d="M216,32H88a8,8,0,0,0-8,8V80H40a8,8,0,0,0-8,8V216a8,8,0,0,0,8,8H168a8,8,0,0,0,8-8V176h40a8,8,0,0,0,8-8V40A8,8,0,0,0,216,32ZM160,208H48V96H160Zm48-48H176V88a8,8,0,0,0-8-8H96V48H208Z"></path></svg>
          </button>
        </div>
      </pre>
    </>
  );
}
const Position = () => {

  return (
    <motion.div
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -10, opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <Card
        CardTitle='Positions'
        CardBody={<PositionSnippet />}
      />
    </motion.div>
  );
}

Position.propTypes = {};

Position.defaultProps = {};

export default Position;
