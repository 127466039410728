import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import Logo from "../../assets/image/carve.png";

const Navbar = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]); // Placeholder for search results, replace with your actual data
  const allData = [
    { id: 1, name: "Accordion", to: "/accordion" },
    { id: 2, name: "Button", to: "/button" },
    { id: 3, name: "Badge", to: "/badge" },
    { id: 4, name: "Card", to: "/solecard" },
    { id: 5, name: "Checkbox", to: "/checkbox" },
    { id: 6, name: "Chip", to: "/chip" },
    { id: 7, name: "Input", to: "/input" },
    { id: 8, name: "Radio", to: "/radio" },
    { id: 9, name: "Select", to: "/select" },
    { id: 10, name: "Switch", to: "/switch" },
    { id: 11, name: "Tab", to: "/tab" },
    { id: 12, name: "Colors", to: "/colors" },
    { id: 13, name: "Grid", to: "/grid" },
    { id: 14, name: "Position", to: "/position" },
    { id: 15, name: "Size", to: "/size" },
    { id: 16, name: "Space", to: "/space" },
    { id: 17, name: "Text", to: "/text" },
    // ... more items
  ];
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    // Filtering data based on the search query
    const filteredResults = allData.filter((item) =>
      item.name.toLowerCase().includes(query.toLowerCase())
    );

    setSearchResults(filteredResults);
    // Clear search results when the query is empty
    if (query === "") {
      setSearchResults([]);
    }
  };

  const handleClear = () => {
    setSearchQuery("");
    setSearchResults([]);
  };

  return (
    <div className="Navbar">
      <Link
        to="/"
        style={{ display: "flex", flexFlow: "column", alignItems: "center" }}
      >
        <svg width="24" height="24" fill="currentColor" viewBox="0 0 256 256">
          <path d="M218.83,103.77l-80-75.48a1.14,1.14,0,0,1-.11-.11,16,16,0,0,0-21.53,0l-.11.11L37.17,103.77A16,16,0,0,0,32,115.55V208a16,16,0,0,0,16,16H96a16,16,0,0,0,16-16V160h32v48a16,16,0,0,0,16,16h48a16,16,0,0,0,16-16V115.55A16,16,0,0,0,218.83,103.77ZM208,208H160V160a16,16,0,0,0-16-16H112a16,16,0,0,0-16,16v48H48V115.55l.11-.1L128,40l79.9,75.43.11.1Z"></path>
        </svg>
        <sup>Home</sup>
      </Link>
      <div className="search-group">
        <svg
          className="icon icon-search"
          width="24"
          height="24"
          fill="currentColor"
          viewBox="0 0 256 256"
        >
          <path d="M232.49,215.51,185,168a92.12,92.12,0,1,0-17,17l47.53,47.54a12,12,0,0,0,17-17ZM44,112a68,68,0,1,1,68,68A68.07,68.07,0,0,1,44,112Z"></path>
        </svg>
        <input
          type="text"
          required=""
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search..."
        />
        {searchResults.length > 0 && (
          <ul className="search-results carve-list">
            {searchResults.map((result) => (
              <Link to={result.to} onClick={handleClear}>
                <li key={result.id}>{result.name}</li>
              </Link>
            ))}
          </ul>
        )}
      </div>
      <img src={Logo} alt="logo" className="logo" />
    </div>
  );
};

Navbar.propTypes = {};

Navbar.defaultProps = {};

export default Navbar;
