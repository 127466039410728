import React from 'react';
import './CheckBoxSnippet.css';

const CheckBoxSnippet = ({ LeftArrow, RightArrow, ClassTitle, HtmlText, LabeFor, InputType, InputName, InputId }) => (
  <>
    <div className='code-snippet'>
      <pre>
        <code>
          <span className='cn'> {LeftArrow}div</span>
          <span className='cln'> class="</span>
          <span className='cn'>{ClassTitle}</span>
          <span className='cln'>"</span>{RightArrow}

          <br />
          &nbsp;&nbsp;
          <span className='cn'>{LeftArrow}input</span>
          <span className='cln'> type="</span>
          <span className='cn'>{InputType}</span><span className='cln'>"</span>
          <span className='cln'> name="</span>
          <span className='cn'>{InputName}</span><span className='cln'>"</span>
          <span className='cln'> id="</span>
          <span className='cn'>{InputId}</span>
          <span className='cln'>"</span> /{RightArrow}

          <br />
          &nbsp;&nbsp;<span className='cn'>{LeftArrow}label</span>
          <span className='cln'> for="</span>
          <span className='cn'>{LabeFor}</span>
          <span className='cln'>"</span>{RightArrow} this is the checkbox &nbsp;
          <span className='cn'>{LeftArrow}/label{RightArrow}</span>

          <br />

          &nbsp;<span className='cn'>{LeftArrow}/div{RightArrow}</span>
        </code>
      </pre>
    </div>
  </>
);

CheckBoxSnippet.propTypes = {};

CheckBoxSnippet.defaultProps = {};

export default CheckBoxSnippet;
