import React from 'react';
import './SelectSnippet.css';

const SelectSnippet = () => (
  <pre>
    <code>
      <>
        <span className='cn'>&lt;div</span> <span className='cln'>class="</span>input-field select-field<span className='cln'>"</span><span className='cn'>&gt;</span>
        <br />
        &nbsp;<span className='cn'>&lt;select</span> <span className='cln'>name="</span>country<span className='cln'>"</span> <span className='cln'>id="</span>countryList<span className='cln'>"</span><span className='cn'>&gt;</span> <br />
        &nbsp;&nbsp;<span className='cn'>&lt;option</span> <span className='cln'>value="</span>india<span className='cln'>"</span><span className='cn'>&gt;</span> Japan <span className='cn'>&lt;/</span><span className='cn'>option&gt;</span><br />
        &nbsp;&nbsp;<span className='cn'>&lt;option</span> <span className='cln'>value="</span>brazil<span className='cln'>"</span><span className='cn'>&gt;</span> england <span className='cn'>&lt;/</span><span className='cn'>option&gt;</span><br />
        &nbsp;&nbsp;<span className='cn'>&lt;option</span> <span className='cln'>value="</span>german<span className='cln'>"</span><span className='cn'>&gt;</span> maxico <span className='cn'>&lt;/</span><span className='cn'>option&gt;</span><br />
        &nbsp;<span className='cn'>&lt;/select</span><span className='cn'>&gt;</span><br />
        <br /><span className='cn'>&lt;/div</span><span className='cn'>&gt;</span> <br /> <br />
      </>
    </code>
    <code>
      <>
        <span className='cn'>&lt;div</span> <span className='cln'>class="</span>input-field select-field mandate<span className='cln'>"</span><span className='cn'>&gt;</span>
        <br /><span className='cn'>&lt;label</span>
        <span className='cln'> for="</span>
        <span className='cn'>countryList2</span>
        <span className='cln'>"</span>
        <span className='cn'>&gt;</span>
        <span className=''>Enter the value</span>
        <span className='cn'>&lt;/label&gt;</span><br />
        &nbsp;<span className='cn'>&lt;select</span> <span className='cln'>name="</span>country<span className='cln'>"</span> <span className='cln'>id="</span>countryList<span className='cln'>"</span><span className='cn'>&gt;</span> <br />
        &nbsp;&nbsp;<span className='cn'>&lt;option</span> <span className='cln'>value="</span>india<span className='cln'>"</span><span className='cn'>&gt;</span> Japan <span className='cn'>&lt;/</span><span className='cn'>option&gt;</span><br />
        &nbsp;&nbsp;<span className='cn'>&lt;option</span> <span className='cln'>value="</span>brazil<span className='cln'>"</span><span className='cn'>&gt;</span> england <span className='cn'>&lt;/</span><span className='cn'>option&gt;</span><br />
        &nbsp;&nbsp;<span className='cn'>&lt;option</span> <span className='cln'>value="</span>german<span className='cln'>"</span><span className='cn'>&gt;</span> maxico <span className='cn'>&lt;/</span><span className='cn'>option&gt;</span><br />
        &nbsp;<span className='cn'>&lt;/select</span><span className='cn'>&gt;</span><br />
        <br /><span className='cn'>&lt;/div</span><span className='cn'>&gt;</span>
      </>
    </code>
  </pre>
);



export { SelectSnippet };
