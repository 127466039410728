import React from 'react';
import './ChipSnippet.css';

const ChipSnippet = ({ LeftArrow, RightArrow, ClassTitle, HtmlText }) => {
  return (
    <div className='code-snippet'>
      <pre>
        <code>
          <span className='cn'>{LeftArrow}div</span>
          <span className='cln'> class="</span>
          <span className='cn'>{ClassTitle}</span>
          <span className='cln'>"</span>{RightArrow}
          <br />
          &nbsp;&nbsp;{HtmlText}&nbsp;
          <span className='cn'><br />{LeftArrow}/div{RightArrow}</span>
          <br />
        </code>
      </pre>
    </div>
  )
}
const CloseIconChip = ({ LeftArrow, RightArrow, ClassTitle, HtmlText }) => {
  return (
    <div className='code-snippet'>
      <pre>
        <code>
          <span className='cn'>{LeftArrow}div</span>
          <span className='cln'> class="</span>
          <span className='cn'>carve-chips fill chip-remove close-right</span>
          <span className='cln'>"</span>{RightArrow}
          <br />
          &nbsp;&nbsp; <span className='cn'>{LeftArrow}div</span>
          <span className='cln'> class="</span>
          <span className='cn'>icon icon-close</span>
          <span className='cln'>"</span>{RightArrow} &times;
          <span className='cn'> {LeftArrow}/div{RightArrow}</span>
          <br />
             <div>&nbsp;&nbsp;&nbsp;&nbsp;Close icon right</div>
          <span className='cn'>{LeftArrow}/div{RightArrow}</span>
        </code>
      </pre>
    </div>
  )
}
const CloseIconChipLeft = ({ LeftArrow, RightArrow, ClassTitle, HtmlText }) => {
  return (
    <div className='code-snippet'>
      <pre>
        <code>
          <span className='cn'>{LeftArrow}div</span>
          <span className='cln'> class="</span>
          <span className='cn'>carve-chips chip-remove</span>
          <span className='cln'>"</span>{RightArrow}
          <br />
          &nbsp;&nbsp; <span className='cn'>{LeftArrow}div</span>
          <span className='cln'> class="</span>
          <span className='cn'>icon icon-close</span>
          <span className='cln'>"</span>{RightArrow} &times;
          <span className='cn'> {LeftArrow}/div{RightArrow}</span>
          <br />
             <div>&nbsp;&nbsp;&nbsp;&nbsp;Close icon left</div>
          <span className='cn'>{LeftArrow}/div{RightArrow}</span>
        </code>
      </pre>
    </div>
  )
}



export { CloseIconChip, ChipSnippet, CloseIconChipLeft };
