import React, { useRef, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import './Background.css';
import Card from '../Card/Card';
import { BackgroundColorSnippet, ColorSnippet } from '../../snippets/ColorSnippet/ColorSnippet';

const Background = () => {
  // Refs for Background
  const dayRef = useRef(null);
  const nightRef = useRef(null);
  const mainRef = useRef(null);
  const viceRef = useRef(null);

  useEffect(() => {
    const setFontColorBasedOnBackground = (element) => {
      if (element) {
        const bgColor = window.getComputedStyle(element).backgroundColor;
        const lum = getLuminance(bgColor);

        // Use a threshold value to determine whether to use light or dark text
        const threshold = 0.5;
        const fontColor = lum > threshold ? 'black' : 'white';

        element.style.color = fontColor;
      }
    };

    setFontColorBasedOnBackground(dayRef.current);
    setFontColorBasedOnBackground(nightRef.current);
    setFontColorBasedOnBackground(mainRef.current);
    setFontColorBasedOnBackground(viceRef.current);
  }, []);

  const getLuminance = (color) => {
    const rgb = color.match(/\d+/g);
    if (!rgb) return 0;

    const lum = 0.2126 * rgb[0] / 255 + 0.7152 * rgb[1] / 255 + 0.0722 * rgb[2] / 255;
    return lum;
  };

  const snippetContainerRef = useRef(null);
  const [showScanning, setShowScanning] = useState(false);

  const copySnippet = () => {
    if (snippetContainerRef.current) {
      const range = document.createRange();
      range.selectNode(snippetContainerRef.current);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();

      setShowScanning(true);
      setTimeout(() => setShowScanning(false), 1000);
    }
  };

  const colorSnipContRef = useRef(null);
  const [showColorScanning, setColorScan] = useState(false)
  const copyColorSnippet = () => {
    if (colorSnipContRef.current) {
      const range = document.createRange();
      range.selectNode(colorSnipContRef.current);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range); 
      document.execCommand('copy');
      window.getSelection().removeAllRanges();

      setColorScan(true);
      setTimeout(() => setColorScan(false), 1000);
    }
  };

  return (
    <motion.div
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -10, opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <>
        <div className='heading-medium'> <u>Background</u> </div>
        <div className="Background">
          {/* Background Snippet */}
          <div className='wall carve-wall-day' ref={dayRef}>
          day
        </div>
        <div className='wall carve-wall-night' ref={nightRef}>
          night
        </div>
        <div className='wall carve-wall-main' ref={mainRef}>
          main
        </div>
        <div className='wall carve-wall-vice' ref={viceRef}>
          vice
        </div>
        </div>
        <div className={`center-container ${showScanning ? 'scanning' : ''}`}>
          <div className='snippet' ref={snippetContainerRef}>
              <BackgroundColorSnippet />
            </div>
          <button onClick={copySnippet}>
            <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#000000" viewBox="0 0 256 256"><path d="M216,40V168H168V88H88V40Z" opacity="0.2"></path><path d="M216,32H88a8,8,0,0,0-8,8V80H40a8,8,0,0,0-8,8V216a8,8,0,0,0,8,8H168a8,8,0,0,0,8-8V176h40a8,8,0,0,0,8-8V40A8,8,0,0,0,216,32ZM160,208H48V96H160Zm48-48H176V88a8,8,0,0,0-8-8H96V48H208Z"></path></svg>
          </button>

          </div>
      </>
      <>
        <div className='heading-medium' style={{ marginTop: '5rem' }}> <u>Colors</u> </div>
        <div className="Colors">
          {/* Color Snippet */}
          <div className='carve-text-main'>
          main
        </div>
        <div className='carve-text-vice carve-wall-night'>
          vice
        </div>
        <div className='carve-text-body'>
          body
        </div>
        <div className='carve-text-body2'>
          body2
        </div>
        <div className='carve-text-day carve-wall-night'>
          day
        </div>
        <div className='carve-text-night'>
          night
          </div>
        </div>
        <div className={`center-container ${showColorScanning ? 'scanning' : ''}`}>
          <div className='snippet' ref={colorSnipContRef}>
            <ColorSnippet />
          </div>
          <button onClick={copyColorSnippet}>
            <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#000000" viewBox="0 0 256 256"><path d="M216,40V168H168V88H88V40Z" opacity="0.2"></path><path d="M216,32H88a8,8,0,0,0-8,8V80H40a8,8,0,0,0-8,8V216a8,8,0,0,0,8,8H168a8,8,0,0,0,8-8V176h40a8,8,0,0,0,8-8V40A8,8,0,0,0,216,32ZM160,208H48V96H160Zm48-48H176V88a8,8,0,0,0-8-8H96V48H208Z"></path></svg>
          </button>
          </div>
      </>
    </motion.div>
  );
};

const BackgroundCard = () => {
  return (
    <Card
      CardTitle='Colors & Backgrounds'
      CardBody={<Background />}
    />
  );
};

Background.propTypes = {};

Background.defaultProps = {};

export default BackgroundCard;
