import React from 'react';
import './GridSnippet.css';

const GridSnippetLeanBody = () => {
  return (
    <pre>
      <code>
        <>
          <span className='cn'>&lt;div</span>
          <span className='cln'> class="</span>
          <span className='cn'>carve-lean-body</span>
          <span className='cln'>"</span>
          <span className='cn'>&gt;</span>
        </> <br />&nbsp;
        <>

          <>
            <span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>carve-line</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </> <br />&nbsp;
          <>
            &nbsp;<span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>box-12</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </>
          <>
            <span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </>


          <br />
          <>
            &nbsp;<span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </>
        </>
        <br />
        &nbsp;<>

          <>
            <span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>carve-line</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </> <br />&nbsp;
          <>
            &nbsp;<span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>box-6</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </>
          <>
            <span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </> <br />&nbsp;
          <>
            &nbsp;<span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>box-6</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </>
          <>
            <span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </>


          <br />
          <>
            &nbsp;<span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </>
        </>
        <br />
        &nbsp;<>

          <>
            <span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>carve-line</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </> <br />&nbsp;
          <>
            &nbsp;<span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>box-4</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </>
          <>
            <span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </> <br />&nbsp;
          <>
            &nbsp;<span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>box-4</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </>
          <>
            <span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </> <br />&nbsp;
          <>
            &nbsp;<span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>box-4</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </>
          <>
            <span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </>


          <br />
          <>
            &nbsp;<span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </>
        </>
        <br />
        &nbsp;<>

          <>
            <span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>carve-line</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </> <br />&nbsp;
          <>
            &nbsp;<span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>box-3</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </>
          <>
            <span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </> <br />&nbsp;
          <>
            &nbsp;<span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>box-3</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </>
          <>
            <span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </> <br />&nbsp;
          <>
            &nbsp;<span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>box-3</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </>
          <>
            <span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </> <br />&nbsp;
          <>
            &nbsp;<span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>box-3</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </>
          <>
            <span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </>


          <br />
          <>
            &nbsp;<span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </>
        </>
        <br />
        &nbsp;<>

          <>
            <span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>carve-line</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </> <br />&nbsp;
          <>
            &nbsp;<span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>box-2</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </>
          <>
            <span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </> <br />&nbsp;
          <>
            &nbsp;<span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>box-2</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </>
          <>
            <span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </> <br />&nbsp;
          <>
            &nbsp;<span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>box-2</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </>
          <>
            <span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </> <br />&nbsp;
          <>
            &nbsp;<span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>box-2</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </>
          <>
            <span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </> <br />&nbsp;
          <>
            &nbsp;<span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>box-2</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </>
          <>
            <span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </> <br />&nbsp;
          <>
            &nbsp;<span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>box-2</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </>
          <>
            <span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </>


          <br />
          <>
            &nbsp;<span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </>
        </>
        <br />
        <>
          <span className='cn'>&lt;/div</span>
          <span className='cn'>&gt;</span>
        </>
      </code><br />
      <code>We can have a multiple combinations from box-1 to box-12 - (box-2 box-10, box-4 box-8 ..)</code>
    </pre>
  );
}
const GridSnippetFatBody = () => {
  return (
    <pre>
      <code>
        <>
          <span className='cn'>&lt;div</span>
          <span className='cln'> class="</span>
          <span className='cn'>carve-fat-body</span>
          <span className='cln'>"</span>
          <span className='cn'>&gt;</span>
        </> <br />&nbsp;
        <>

          <>
            <span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>carve-line</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </> <br />&nbsp;
          <>
            &nbsp;<span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>box-12</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </>
          <>
            <span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </>


          <br />
          <>
            &nbsp;<span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </>
        </>
        <br />
        &nbsp;<>

          <>
            <span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>carve-line</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </> <br />&nbsp;
          <>
            &nbsp;<span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>box-6</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </>
          <>
            <span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </> <br />&nbsp;
          <>
            &nbsp;<span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>box-6</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </>
          <>
            <span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </>


          <br />
          <>
            &nbsp;<span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </>
        </>
        <br />
        &nbsp;<>

          <>
            <span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>carve-line</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </> <br />&nbsp;
          <>
            &nbsp;<span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>box-4</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </>
          <>
            <span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </> <br />&nbsp;
          <>
            &nbsp;<span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>box-4</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </>
          <>
            <span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </> <br />&nbsp;
          <>
            &nbsp;<span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>box-4</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </>
          <>
            <span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </>


          <br />
          <>
            &nbsp;<span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </>
        </>
        <br />
        &nbsp;<>

          <>
            <span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>carve-line</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </> <br />&nbsp;
          <>
            &nbsp;<span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>box-3</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </>
          <>
            <span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </> <br />&nbsp;
          <>
            &nbsp;<span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>box-3</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </>
          <>
            <span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </> <br />&nbsp;
          <>
            &nbsp;<span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>box-3</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </>
          <>
            <span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </> <br />&nbsp;
          <>
            &nbsp;<span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>box-3</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </>
          <>
            <span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </>


          <br />
          <>
            &nbsp;<span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </>
        </>
        <br />
        &nbsp;<>

          <>
            <span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>carve-line</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </> <br />&nbsp;
          <>
            &nbsp;<span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>box-2</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </>
          <>
            <span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </> <br />&nbsp;
          <>
            &nbsp;<span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>box-2</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </>
          <>
            <span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </> <br />&nbsp;
          <>
            &nbsp;<span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>box-2</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </>
          <>
            <span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </> <br />&nbsp;
          <>
            &nbsp;<span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>box-2</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </>
          <>
            <span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </> <br />&nbsp;
          <>
            &nbsp;<span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>box-2</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </>
          <>
            <span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </> <br />&nbsp;
          <>
            &nbsp;<span className='cn'>&lt;div</span>
            <span className='cln'> class="</span>
            <span className='cn'>box-2</span>
            <span className='cln'>"</span>
            <span className='cn'>&gt;</span>
          </>
          <>
            <span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </>


          <br />
          <>
            &nbsp;<span className='cn'>&lt;/div</span>
            <span className='cn'>&gt;</span>
          </>
        </>
        <br />
        <>
          <span className='cn'>&lt;/div</span>
          <span className='cn'>&gt;</span>
        </>
      </code><br />
      <code>We can have a multiple combinations from box-1 to box-12 - (box-2 box-10, box-4 box-8 ..)</code>
    </pre>
  );
}


export { GridSnippetLeanBody, GridSnippetFatBody };
