import React from 'react';
import './CodeSnippet.css';

const CodeSnippet = ({ LeftArrow, RightArrow, ClassTitle, HtmlText }) => (
  <>
    <div className='code-snippet'>
      <pre>
        <code>
          <span className='cn'> {LeftArrow}button</span> <span className='cln'>class="</span><span className='cn'>{ClassTitle}</span><span className='cln'>"</span>
          {RightArrow} {HtmlText} <span className='cn'>{LeftArrow}/button{RightArrow}</span>
        </code>
      </pre>
    </div>
  </>
);

CodeSnippet.propTypes = {};

CodeSnippet.defaultProps = {};

export default CodeSnippet;
