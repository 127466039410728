import React from 'react';
import './ColorSnippet.css';

const BackgroundColorSnippet = () => {
  return(
    <pre>
      <code className="ColorSnippet">
        <span className='cn'>&lt;div</span><span className='cln'> class="</span>carve-wall-day<span className='cln'>"</span><span className='cn'>&gt;</span>
        day
        <span className='cn'>&lt;/div</span>
        <span className='cn'>&gt;</span><br></br><br></br>
        <span className='cn'>&lt;div</span>  <span className='cln'> class="</span>carve-wall-night<span className='cln'>"</span><span className='cn'>&gt;</span>
        night
        <span className='cn'>&lt;/div</span>
        <span className='cn'>&gt;</span><br></br><br></br>
        <span className='cn'>&lt;div</span>  <span className='cln'> class="</span>carve-wall-main<span className='cln'>"</span><span className='cn'>&gt;</span>
        main
        <span className='cn'>&lt;/div</span>
        <span className='cn'>&gt;</span><br></br><br></br>
        <span className='cn'>&lt;div</span>  <span className='cln'> class="</span>carve-wall-vice<span className='cln'>"</span><span className='cn'>&gt;</span>
        vice
        <span className='cn'>&lt;/div</span>
        <span className='cn'>&gt;</span><br></br><br></br>
      </code>
    </pre>
  );
}
const ColorSnippet = () => {
  return(
    <pre>
      
      <code className="ColorSnippet">
        <span className='cn'>&lt;div</span><span className='cln'> class="</span>carve-text-main<span className='cln'>"</span><span className='cn'>&gt;</span>
        main
        <span className='cn'>&lt;/div</span>
        <span className='cn'>&gt;</span><br></br><br></br>
        <span className='cn'>&lt;div</span>  <span className='cln'> class="</span>carve-text-vice<span className='cln'>"</span><span className='cn'>&gt;</span>
        vice
        <span className='cn'>&lt;/div</span>
        <span className='cn'>&gt;</span><br></br><br></br>
        <span className='cn'>&lt;div</span>  <span className='cln'> class="</span>carve-text-body<span className='cln'>"</span><span className='cn'>&gt;</span>
        body
        <span className='cn'>&lt;/div</span>
        <span className='cn'>&gt;</span><br></br><br></br>
        <span className='cn'>&lt;div</span>  <span className='cln'> class="</span>carve-text-body2<span className='cln'>"</span><span className='cn'>&gt;</span>
        body2
        <span className='cn'>&lt;/div</span>
        <span className='cn'>&gt;</span><br></br><br></br>
        <span className='cn'>&lt;div</span>  <span className='cln'> class="</span>carve-text-day<span className='cln'>"</span><span className='cn'>&gt;</span>
        day
        <span className='cn'>&lt;/div</span>
        <span className='cn'>&gt;</span><br></br><br></br>
        <span className='cn'>&lt;div</span>  <span className='cln'> class="</span>carve-text-night<span className='cln'>"</span><span className='cn'>&gt;</span>
        night
        <span className='cn'>&lt;/div</span>
        <span className='cn'>&gt;</span><br></br><br></br>
      </code>
    </pre>
  );
}


export {BackgroundColorSnippet,ColorSnippet};
