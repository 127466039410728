import React from "react";

import "./Spa-snippet.css";

const LeftArrow = "<";
const RightArrow = ">";
const BodyClass = "";
const DivId = "body";

const CodeSnippetBody = () => (
  <div className="code-snippet">
    <pre>
      <code>
        <span className="cn">{LeftArrow}body</span>
        <span className="cln"> class="</span>
        <span className="cn">{BodyClass}</span>
        <span className="cln">"</span>
        {RightArrow}
        <br />
        &nbsp;&nbsp;<span className="cn">{LeftArrow}div</span>
        <span className="cln"> id="</span>
        <span className="cn">{DivId}</span>
        <span className="cln">"</span>
        {RightArrow}
        <span className="cn">{LeftArrow}/div</span>
        {RightArrow}
        <br />
        <span className="cn">{LeftArrow}/body</span>
        {RightArrow}
      </code>
    </pre>
  </div>
);
const ScriptTag = "script";
const SrcAttr = "src";
const ScriptSrc1 = "js/lib/jquery.min.js";
const ScriptSrc2 = "routes/routes.js";
const ScriptSrc3 = "routes/router.js";

const ScriptSnippet = () => (
  <div className="code-snippet">
    <pre>
      <code>
        <span className="cn">
          {LeftArrow}
          {ScriptTag}
        </span>
        <span className="cln"> {SrcAttr}="</span>
        <span className="cn">{ScriptSrc1}</span>
        <span className="cln">"{RightArrow}</span>
        <span className="cn">
          {LeftArrow}/{ScriptTag}
          {RightArrow}
        </span>
        <br />
        <span className="cn">
          {LeftArrow}
          {ScriptTag}
        </span>
        <span className="cln"> {SrcAttr}="</span>
        <span className="cn">{ScriptSrc2}</span>
        <span className="cln">"{RightArrow}</span>
        <span className="cn">
          {LeftArrow}/{ScriptTag}
          {RightArrow}
        </span>
        <br />
        <span className="cn">
          {LeftArrow}
          {ScriptTag}
        </span>
        <span className="cln"> {SrcAttr}="</span>
        <span className="cn">{ScriptSrc3}</span>
        <span className="cln">"{RightArrow}</span>
        <span className="cn">
          {LeftArrow}/{ScriptTag}
          {RightArrow}
        </span>
      </code>
    </pre>
  </div>
);

const HrefAttr = "href";
const LinkText = "Login";
const LinkHref = "/login";

const LinkSnippet = () => (
  <div className="code-snippet">
    <pre>
      <code>
        <span className="cn">{LeftArrow}a</span>
        <span className="cln"> {HrefAttr}="</span>
        <span className="cn">{LinkHref}</span>
        <span className="cln">"{RightArrow}</span>
        {LinkText}
        <span className="cn">
          {LeftArrow}/a{RightArrow}
        </span>
      </code>
    </pre>
  </div>
);

const FunctionName = "routing";
const FunctionParam = '"/login"';

const RoutingSnippet = () => (
  <div className="code-snippet">
    <pre>
      <code>
        <span className="nf">{FunctionName}</span>
        <span className="nf">{FunctionName}</span>
        <span className="p">(</span>
        <span className="s">{FunctionParam}</span>
        <span className="p">);</span>
      </code>
    </pre>
  </div>
);

const dataType = "var";
const RouteName = "routing";
const Quotes = '"';
const Colen = '" : "';
const OpenBracket = "{";
const CloseBracket = "}";
const ObjectKey1 = "/";
const ObjectKey2 = "/login";
const ObjectValue1 = "pages/login.html";
const ObjectValue2 = 'pages/login.html';
const RouteSnippet = () => (
  <div className="code-snippet">
    <pre>
      <code>
        <span className="s">{dataType} </span>
        <span className="cln">{RouteName} = </span>
        <span className="s">{OpenBracket}</span>
        <br />
        <span className="nf">{Quotes}</span>
        <span className="nf">{ObjectKey1}</span>
        <span className="nf">{Colen}</span>
        <span className="s">{ObjectValue1}</span>
        <span className="nf">{Quotes},</span>
        <br />
        <span className="nf">{Quotes}</span>
        <span className="nf">{ObjectKey2}</span>
        <span className="nf">{Colen}</span>
        <span className="s">{ObjectValue2}</span>
        <span className="nf">{Quotes}</span>
        <br />
        <span className="s">{CloseBracket};</span>
      </code>
    </pre>
  </div>
);

export {
  CodeSnippetBody,
  ScriptSnippet,
  LinkSnippet,
  RoutingSnippet,
  RouteSnippet,
};
