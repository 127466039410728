import React, { useRef, useState } from 'react';
import { motion } from 'framer-motion';
import './space.css';
import Card from '../Card/Card';

const SpaceSnipp = () => {

  const widthSnippetRef = useRef(null);
  const [showScanning, setShowScanning] = useState(false);

  const wCopySnippet = () => {
    if (widthSnippetRef.current) {
      const range = document.createRange();
      range.selectNode(widthSnippetRef.current);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();

      setShowScanning(true);
      setTimeout(() => setShowScanning(false), 1000);
    }
  };
  const hSnippetRef = useRef(null);
  const [hShowScanning, hSetShowScanning] = useState(false);

  const hCopySnippet = () => {
    if (hSnippetRef.current) {
      const range = document.createRange();
      range.selectNode(hSnippetRef.current);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();

      hSetShowScanning(true);
      setTimeout(() => hSetShowScanning(false), 1000);
    }
  };
  return (
    <>
      <pre>
        <div className={`center-container ${showScanning ? 'scanning' : ''}`}>
          <div className='snippet' ref={widthSnippetRef}>
            <code>
              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-space-tiny<span className='cln'>"</span><span className='cn'>&gt;</span>
              margin: 1.5rem;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>

              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-space-norm<span className='cln'>"</span><span className='cn'>&gt;</span>
              margin: 2rem;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>

              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-space-top-tiny<span className='cln'>"</span><span className='cn'>&gt;</span>
              margin-top: 1.5rem;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>

              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-space-top-norm<span className='cln'>"</span><span className='cn'>&gt;</span>
              margin-top: 2rem;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>

              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-space-top-hefty<span className='cln'>"</span><span className='cn'>&gt;</span>
              margin-top: 2.5rem;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>

              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-space-bottom-tiny<span className='cln'>"</span><span className='cn'>&gt;</span>
              margin-bottom: 1.5rem;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>

              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-space-bottom-norm<span className='cln'>"</span><span className='cn'>&gt;</span>
              margin-bottom: 2rem;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>

              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-space-bottom-hefty<span className='cln'>"</span><span className='cn'>&gt;</span>
              margin-bottom: 2.5rem;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>

              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-space-left-tiny<span className='cln'>"</span><span className='cn'>&gt;</span>
              margin-left: 1.5rem;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>

              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-space-left-norm<span className='cln'>"</span><span className='cn'>&gt;</span>
              margin-left: 2rem;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>

              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-space-left-hefty<span className='cln'>"</span><span className='cn'>&gt;</span>
              margin-left: 2.5rem;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>

              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-space-right-tiny<span className='cln'>"</span><span className='cn'>&gt;</span>
              margin-right: 1.5rem;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>

              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-space-right-norm<span className='cln'>"</span><span className='cn'>&gt;</span>
              margin-right: 2rem;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>

              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-space-right-hefty<span className='cln'>"</span><span className='cn'>&gt;</span>
              margin-right: 2.5rem;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>
            </code>
          </div>
          <button onClick={wCopySnippet}>
            <svg className="icon" width="25" height="25" fill="#000000" viewBox="0 0 256 256"><path d="M216,40V168H168V88H88V40Z" opacity="0.2"></path><path d="M216,32H88a8,8,0,0,0-8,8V80H40a8,8,0,0,0-8,8V216a8,8,0,0,0,8,8H168a8,8,0,0,0,8-8V176h40a8,8,0,0,0,8-8V40A8,8,0,0,0,216,32ZM160,208H48V96H160Zm48-48H176V88a8,8,0,0,0-8-8H96V48H208Z"></path></svg>
          </button>
        </div>
        <div className={`center-container ${hShowScanning ? 'scanning' : ''}`}>
          <div className='snippet' ref={hSnippetRef}>
            <code>
            <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-gap-tiny<span className='cln'>"</span><span className='cn'>&gt;</span>
              padding: 1.5rem;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>

              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-gap-norm<span className='cln'>"</span><span className='cn'>&gt;</span>
              padding: 2rem;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>

              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-gap-top-tiny<span className='cln'>"</span><span className='cn'>&gt;</span>
              padding-top: 1.5rem;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>

              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-gap-top-norm<span className='cln'>"</span><span className='cn'>&gt;</span>
              padding-top: 2rem;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>

              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-gap-top-hefty<span className='cln'>"</span><span className='cn'>&gt;</span>
              padding-top: 2.5rem;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>

              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-gap-bottom-tiny<span className='cln'>"</span><span className='cn'>&gt;</span>
              padding-bottom: 1.5rem;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>

              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-gap-bottom-norm<span className='cln'>"</span><span className='cn'>&gt;</span>
              padding-bottom: 2rem;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>

              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-gap-bottom-hefty<span className='cln'>"</span><span className='cn'>&gt;</span>
              padding-bottom: 2.5rem;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>

              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-gap-left-tiny<span className='cln'>"</span><span className='cn'>&gt;</span>
              padding-left: 1.5rem;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>

              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-gap-left-norm<span className='cln'>"</span><span className='cn'>&gt;</span>
              padding-left: 2rem;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>

              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-gap-left-hefty<span className='cln'>"</span><span className='cn'>&gt;</span>
              padding-left: 2.5rem;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>

              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-gap-right-tiny<span className='cln'>"</span><span className='cn'>&gt;</span>
              padding-right: 1.5rem;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>

              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-gap-right-norm<span className='cln'>"</span><span className='cn'>&gt;</span>
              padding-right: 2rem;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>

              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-gap-right-hefty<span className='cln'>"</span><span className='cn'>&gt;</span>
              padding-right: 2.5rem;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>
            </code>
          </div>
          <button onClick={hCopySnippet}>
            <svg className="icon" width="25" height="25" fill="#000000" viewBox="0 0 256 256"><path d="M216,40V168H168V88H88V40Z" opacity="0.2"></path><path d="M216,32H88a8,8,0,0,0-8,8V80H40a8,8,0,0,0-8,8V216a8,8,0,0,0,8,8H168a8,8,0,0,0,8-8V176h40a8,8,0,0,0,8-8V40A8,8,0,0,0,216,32ZM160,208H48V96H160Zm48-48H176V88a8,8,0,0,0-8-8H96V48H208Z"></path></svg>
          </button>
        </div>
      </pre>
    </>
  );
}

const Space = () => {
  return (
    <motion.div
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -10, opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <Card
        CardTitle='Margin & Padding'
        CardBody={<SpaceSnipp />}
      />
    </motion.div>
  );
}

Space.propTypes = {};

Space.defaultProps = {};

export default Space;
