import React from "react";
import Vlogo from "../../assets/image/emv.png";
import Rlogo from "../../assets/image/rs.png";
import "./Bottombar.css";

const Bottombar = () => (
  <div className="Bottombar">
    {/* Footer */}
    <footer className="carve-h-footer">
      <p>
        <div>
          <span style={{ lineHeight: "2" }}>
            A product of{" "}
          </span>
          <a href="https://enminvithaigal.in" target="_blank" rel="noreferrer">
            <img src={Vlogo} alt="v-logo" />
            <span style={{ color: "#cc3333", fontSize: "1.3rem" }}>எ</span>
            <span style={{ color: "#339999", fontSize: "1.3rem" }}>ண்</span>
            <span style={{ color: "#cc3333", fontSize: "1.3rem" }}>மின்</span>
            <span style={{ color: "#669900", fontSize: "1.3rem" }}>
              விதைகள்
            </span>
          </a>
        </div>
        <div>
          <span>
            &nbsp; &nbsp; © 2024 Carve CSS Library &nbsp; | &nbsp; All rights
            reserved.
          </span>
        </div>
        <div>
          <span style={{ lineHeight: "2" }}>
            {" "}
            &nbsp; &nbsp; Powered by
          </span>
          <a href="https://www.rajasri.com/" target="_blank" rel="noreferrer">
            <img src={Rlogo} alt="r-logo" />
            <span style={{ color: "#dd2725" }}>Ra</span>
            <span style={{ color: "#339999" }}>ja</span>
            <span style={{ color: "#dd2725" }}>sri</span>
            <span style={{ color: "#f6f5f5" }}>&nbsp;Systems</span>
          </a>
        </div>
      </p>
    </footer>
  </div>
);

Bottombar.propTypes = {};

Bottombar.defaultProps = {};

export default Bottombar;
