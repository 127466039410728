import React, { useRef, useState } from 'react';
import { motion } from 'framer-motion';
import './Input.css';
import Card from '../Card/Card';
import { InputSnippet } from '../../snippets/InputSnippet/InputSnippet';

const CardTitle = ({ text }) => <>{text}</>
const CustomInputField = ({ id, label, required, baseline, mandate }) => {
  const classNames = [];
  if (baseline) classNames.push('baseline');
  if (mandate) classNames.push('mandate');

  return (
    <div className={`input-field ${classNames.join(' ')}`}>
      <input type="text" required={required} id={id} />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

const Input = () => {
  const snippetContainerRef = useRef(null);
  const [showScanning, setShowScanning] = useState(false);

  const copySnippet = () => {
    if (snippetContainerRef.current) {
      const range = document.createRange();
      range.selectNode(snippetContainerRef.current);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();

      setShowScanning(true);
      setTimeout(() => setShowScanning(false), 1000);
    }
  };
  return (
    <motion.div
    initial={{ y: 10, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    exit={{ y: -10, opacity: 0 }}
    transition={{ duration: 0.2 }}
  >
    <div className="Inputs">
      <CustomInputField id="value1" label="Enter the value" required baseline mandate />
      <CustomInputField id="value2" label="Enter the value" required baseline />
      <CustomInputField id="value3" label="Enter the value" required mandate />
      <CustomInputField id="value4" label="Enter the value" required />
        <code>note: add/remove [baseline, mandate] classNames, which is combined with 
        input-field className, in devtool for different input UI </code>
        <div className={`center-container ${showScanning ? 'scanning' : ''}`}>
          <div className='snippet' ref={snippetContainerRef}>
            <InputSnippet />
            <button onClick={copySnippet}>
              <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#000000" viewBox="0 0 256 256"><path d="M216,40V168H168V88H88V40Z" opacity="0.2"></path><path d="M216,32H88a8,8,0,0,0-8,8V80H40a8,8,0,0,0-8,8V216a8,8,0,0,0,8,8H168a8,8,0,0,0,8-8V176h40a8,8,0,0,0,8-8V40A8,8,0,0,0,216,32ZM160,208H48V96H160Zm48-48H176V88a8,8,0,0,0-8-8H96V48H208Z"></path></svg>
        </button>
        
          </div>

        </div>
    </div>
  </motion.div>
  );
}

const InputCard = () => {
  return (
    <Card
      CardTitle={<CardTitle text='Inputs' />}
      CardBody={<Input />}
    />
  );
}

export default InputCard;
