import React, { useRef, useState } from 'react';
import Card from '../Card/Card';
import './SoleCard.css';
import CardSnippet from './../../snippets/CardSnippet/CardSnippet';
const CardTitle = () => {
  return (
    <>
      card header
    </>
  )
}
const CardBody = () => {
  return (
    <>
      <p> Lorem ipsum dolor, sit amet consectetur adipiscing elit. Dolore quod maxime illo,
        veritatis ea quisquam voluptate. Corrupti, nisi reprehenderit rem dolorem rerum
        nulla obcaecati, quidem, deleniti deserunt itaque odit officia!
      </p>
    </>
  )
}
const CardFooter = () => {
  return (
    <>
      <div className="carve-button-group">
        <button className="carve-button-vice carve-button-block">save</button>
        <button className="carve-button-main carve-button-block">cancel</button>
      </div>
    </>
  )
}
const SoleCard = () => {
  const snippetContainerRef = useRef(null);
  const [showScanning, setShowScanning] = useState(false);

  const copySnippet = () => {
    if (snippetContainerRef.current) {
      const range = document.createRange();
      range.selectNode(snippetContainerRef.current);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();

      setShowScanning(true);
      setTimeout(() => setShowScanning(false), 1000);
    }
  };
  return (
    <div className="SoleCard" >
      <Card 
        CardTitle={<CardTitle />}
        CardBody={<CardBody />}
        CardFooter={<CardFooter />}
      />
      <div className={`center-container ${showScanning ? 'scanning' : ''}`}>
        <div className='snippet' ref={snippetContainerRef}>
          <CardSnippet />
        </div>
        <button onClick={copySnippet}>
          <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#000000" viewBox="0 0 256 256"><path d="M216,40V168H168V88H88V40Z" opacity="0.2"></path><path d="M216,32H88a8,8,0,0,0-8,8V80H40a8,8,0,0,0-8,8V216a8,8,0,0,0,8,8H168a8,8,0,0,0,8-8V176h40a8,8,0,0,0,8-8V40A8,8,0,0,0,216,32ZM160,208H48V96H160Zm48-48H176V88a8,8,0,0,0-8-8H96V48H208Z"></path></svg>
        </button>
      </div>
    </div>
  );
}

const NestCard = () => {
  return (
    <Card 
      CardTitle='Card'
      CardBody={<SoleCard />}
    />
  );
}
SoleCard.propTypes = {};

SoleCard.defaultProps = {};

export default NestCard;
