import React from 'react';
import './CardSnippet.css';

const CardSnippet = () => {
  return (
    <pre>
    <code>
        <span className='cn'>&lt;div</span> <span className='cln'> class=<span className='cln'>"</span></span>carve-card<span className='cln'>"</span><span className='cn'>&gt;</span>  <br />
        &nbsp;<span className='cn'>&lt;div</span> <span className='cln'> class=<span className='cln'>"</span></span>carve-card-head<span className='cln'>"</span><span className='cn'>&gt;</span>  <br />
        &nbsp;&nbsp;<span className='cn'>&lt;div</span> <span className='cln'> class=<span className='cln'>"</span></span>heading-medium<span className='cln'>"</span><span className='cn'>&gt;</span>card header<span className='cn'>&lt;/div</span><span className='cn'>&gt;</span>  <br /> &nbsp;<span className='cn'>&lt;/div</span>
      <span className='cn'>&gt;</span>
         <br />
        &nbsp;<span className='cn'>&lt;div</span>
        <span className='cln'> class=<span className='cln'>"</span></span>carve-card-body<span className='cln'>"</span><span className='cn'>&gt;</span>
         <br />

        <p> &nbsp;&nbsp;&nbsp;Lorem ipsum dolor, sit amet consectetur adipiscing elit. Dolore quod maxime illo,
        veritatis ea quisquam voluptate. Corrupti, nisi reprehenderit rem dolorem rerum
          nulla obcaecati, quidem, deleniti deserunt itaque odit officia!  <br />
      </p>
        &nbsp;<span className='cn'>&lt;/div</span><span className='cn'>&gt;</span> <br />

        &nbsp;<span className='cn'>&lt;div</span> <span className='cln'> class=<span className='cln'>"</span></span>carve-card-footer<span className='cln'>"</span><span className='cn'>&gt;</span> <br />
        &nbsp;&nbsp;<span className='cn'>&lt;div</span> <span className='cln'> class=<span className='cln'>"</span></span>carve-button-group<span className='cln'>"</span><span className='cn'>&gt;</span> <br />
        &nbsp;&nbsp;&nbsp;<span className='cn'>&lt;button</span> <span className='cln'> class=<span className='cln'>"</span></span>carve-button-vice<span className='cln'>"</span><span className='cn'>&gt;</span>save<span className='cn'>&lt;/button</span><span className='cn'>&gt;</span> <br />
        &nbsp;&nbsp;&nbsp;<span className='cn'>&lt;button</span> <span className='cln'> class=<span className='cln'>"</span></span>carve-button-main<span className='cln'>"</span><span className='cn'>&gt;</span>cancel<span className='cn'>&lt;/button</span><span className='cn'>&gt;</span> <br />

        &nbsp;&nbsp;<span className='cn'>&lt;/div</span><span className='cn'>&gt;</span>
         <br />
        &nbsp;<span className='cn'>&lt;/div</span><span className='cn'>&gt;</span>
         <br />
        <span className='cn'>&lt;/div</span><span className='cn'>&gt;</span>
    </code>
  </pre>
);
}

CardSnippet.propTypes = {};

CardSnippet.defaultProps = {};

export default CardSnippet;
