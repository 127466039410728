import React, { useRef, useState } from 'react';
import { motion } from 'framer-motion';
import './Checkbox.css';
import Card from '../Card/Card';
import CheckBoxSnippet from '../../snippets/CheckBoxSnippet/CheckBoxSnippet';

const LeftArrow = () => <>&lt;</>;
const RightArrow = () => <>&gt;</>;
const HtmlText = ({ text }) => <>{text}</>;
const ClassTitle = ({ className }) => <>{className}</>;
const InputType = ({ type }) => <>{type}</>
const InputName = ({ name }) => <>{name}</>
const InputId = ({ id }) => <>{id}</>
const LabeFor = ({ text }) => <>{text}</>
const CheckboxField = ({ name, id, label }) => (
  <div className="check-field">
    <input type="checkbox" name={name} id={id} />
    <label htmlFor={id}>{label}</label>
  </div>
);
const Checkbox = () => {
  const snippetContainerRef = useRef(null);
  const [showScanning, setShowScanning] = useState(false);

  const copySnippet = () => {
    if (snippetContainerRef.current) {
      const range = document.createRange();
      range.selectNode(snippetContainerRef.current);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();

      setShowScanning(true);
      setTimeout(() => setShowScanning(false), 1000);
    }
  };
  return (
  <motion.div
    initial={{ y: 10, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    exit={{ y: -10, opacity: 0 }}
    transition={{ duration: 0.2 }}
  >
    <CheckboxField name="one" id="checkOne" label="this is the checkbox" />
    <CheckboxField name="one" id="checkTwo" label="this is the checkbox" />
      <div className={`center-container ${showScanning ? 'scanning' : ''}`}>
        <div className='snippet' ref={snippetContainerRef}>
          <CheckBoxSnippet
            LeftArrow={<LeftArrow />}
            RightArrow={<RightArrow />}
            InputType={<InputType type='checkbox' />}
            InputName={<InputName name='input' />}
            InputId={<InputId id='check1' />}
            LabeFor={<LabeFor text='check1' />}
            ClassTitle={<ClassTitle className='check-field' />}
          />
        </div>
        <button onClick={copySnippet}>
          <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#000000" viewBox="0 0 256 256"><path d="M216,40V168H168V88H88V40Z" opacity="0.2"></path><path d="M216,32H88a8,8,0,0,0-8,8V80H40a8,8,0,0,0-8,8V216a8,8,0,0,0,8,8H168a8,8,0,0,0,8-8V176h40a8,8,0,0,0,8-8V40A8,8,0,0,0,216,32ZM160,208H48V96H160Zm48-48H176V88a8,8,0,0,0-8-8H96V48H208Z"></path></svg>
        </button>
        
      </div>
  </motion.div>
  );
}

const CheckCard = () => {
  return (
    <Card
      CardTitle={<HtmlText text='Check Box' />}
      CardBody={<Checkbox />}
    />
  );
}

export default CheckCard;