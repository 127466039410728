import React from 'react';
import './SwitchSnippet.css';

const SwitchSnippet = () => {
  return (
    <pre>
      <code>
        <span className='cn'>&lt;div</span><span className='cln'> class="</span>carve-switch<span className='cln'>"</span><span className='cn'>&gt;</span><br />
        &nbsp;<span className='cn'>&lt;input</span><span className='cln'> type="</span>checkbox<span className='cln'>"</span><span className='cln'> name="</span>toggle-carve-switch"<span className='cln'> id="</span>toggleSwitch<span className='cln'>"</span>/<span className='cn'>&gt;</span><br />
        &nbsp;<span className='cn'>&lt;label</span><span className='cln'> for="</span>toggleSwitch<span className='cln'>"</span><span className='cn'>&gt;</span><br />
        &nbsp;&nbsp;<span className='cn'>&lt;span</span><span className='cln'> class="</span>on<span className='cln'>"</span><span className='cn'>&gt;</span>ON<span className='cn'>&lt;/</span>span<span className='cn'>&gt;</span><br />
        &nbsp;&nbsp;<span className='cn'>&lt;span</span><span className='cln'> class="</span>off<span className='cln'>"</span><span className='cn'>&gt;</span>OFF<span className='cn'>&lt;/</span>span<span className='cn'>&gt;</span><br />
        &nbsp;<span className='cn'>&lt;/label</span><span className='cn'>&gt;</span><br />
        <span className='cn'>&lt;/div</span><span className='cn'>&gt;</span>
      </code>
    </pre>

  );
}

SwitchSnippet.propTypes = {};

SwitchSnippet.defaultProps = {};

export default SwitchSnippet;
