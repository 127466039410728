import React from 'react';
import './AccordSnippet.css';

const AccordSnippet = ({ LeftArrow, RightArrow, Heading }) => (

  <div className='code-snippet'>
    <pre>
      <code>
        {/* accordion parent  */}
      <span className='cn'>{LeftArrow}div</span> <span className='cln'>class="</span>
      <span className='cn'>carve-accordions</span><span className='cln'>"</span><span className='cln'>{RightArrow}</span>
      <br />
      {/* accordion 1 */}
      &nbsp;<span className='cn'>{LeftArrow}div</span> <span className='cln'>class="</span>
        <span className='cn'>carve-accordion</span><span className='cln'>"</span><span className='cln'>{RightArrow}</span>
        <br />
        &nbsp;&nbsp;<span className='cn'>{LeftArrow}div</span> <span className='cln'>class="</span>
        <span className='cn'>carve-accordion-head</span><span className='cln'>"</span><span className='cln'>{RightArrow}</span>
        <br />
        &nbsp;&nbsp;&nbsp;<span className='cn'>{LeftArrow}div</span> <span className='cln'>class="</span>
        <span className='cn'>heading-small outer-space-0</span><span className='cln'>"</span><span className='cln'>{RightArrow}</span>
        Accordion 1<span className='cn'>{LeftArrow}/div{RightArrow}</span>
        <br />
        &nbsp;&nbsp;<span className='cn'>{LeftArrow}/div{RightArrow}</span>
        <br />
        &nbsp;&nbsp;<span className='cn'>{LeftArrow}div</span> <span className='cln'>class="</span>
        <span className='cn'>carve-accordion-body</span><span className='cln'>"</span><span className='cln'>{RightArrow}</span>
        <br />
        &nbsp;&nbsp;&nbsp;<span className='cn'>{LeftArrow}p{RightArrow}</span>&nbsp;
        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero.</span>
        <span className='cn'>{LeftArrow}/p{RightArrow}</span>
        <br />
        &nbsp;&nbsp;<span className='cn'>{LeftArrow}/div{RightArrow}</span> 
        <br />
        &nbsp;<span className='cn'>{LeftArrow}/div{RightArrow}</span>
        {/* accordion 1 */}
        <br />
      {/* accordion 2 */}
      &nbsp;<span className='cn'>{LeftArrow}div</span> <span className='cln'>class="</span>
        <span className='cn'>carve-accordion</span><span className='cln'>"</span><span className='cln'>{RightArrow}</span>
        <br />
        &nbsp;&nbsp;<span className='cn'>{LeftArrow}div</span> <span className='cln'>class="</span>
        <span className='cn'>carve-accordion-head</span><span className='cln'>"</span><span className='cln'>{RightArrow}</span>
        <br />
        &nbsp;&nbsp;&nbsp;<span className='cn'>{LeftArrow}div</span> <span className='cln'>class="</span>
        <span className='cn'>heading-small outer-space-0</span><span className='cln'>"</span><span className='cln'>{RightArrow}</span>
        Accordion 2<span className='cn'>{LeftArrow}/div{RightArrow}</span>
        <br />
        &nbsp;&nbsp;<span className='cn'>{LeftArrow}/div{RightArrow}</span>
        <br />
        &nbsp;&nbsp;<span className='cn'>{LeftArrow}div</span> <span className='cln'>class="</span>
        <span className='cn'>carve-accordion-body</span><span className='cln'>"</span><span className='cln'>{RightArrow}</span>
        <br />
        &nbsp;&nbsp;&nbsp;<span className='cn'>{LeftArrow}p{RightArrow}</span>&nbsp;
        <span>Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent mauris.</span>
        <span className='cn'>{LeftArrow}/p{RightArrow}</span>
        <br />
        &nbsp;&nbsp;<span className='cn'>{LeftArrow}/div{RightArrow}</span> 
        <br />
        &nbsp;<span className='cn'>{LeftArrow}/div{RightArrow}</span>
        {/* accordion 2 */}
        <br />
      {/* accordion 3 */}
      &nbsp;<span className='cn'>{LeftArrow}div</span> <span className='cln'>class="</span>
        <span className='cn'>carve-accordion</span><span className='cln'>"</span><span className='cln'>{RightArrow}</span>
        <br />
        &nbsp;&nbsp;<span className='cn'>{LeftArrow}div</span> <span className='cln'>class="</span>
        <span className='cn'>carve-accordion-head</span><span className='cln'>"</span><span className='cln'>{RightArrow}</span>
        <br />
        &nbsp;&nbsp;&nbsp;<span className='cn'>{LeftArrow}div</span> <span className='cln'>class="</span>
        <span className='cn'>heading-small outer-space-0</span><span className='cln'>"</span><span className='cln'>{RightArrow}</span>
        Accordion 3<span className='cn'>{LeftArrow}/div{RightArrow}</span>
        <br />
        &nbsp;&nbsp;<span className='cn'>{LeftArrow}/div{RightArrow}</span>
        <br />
        &nbsp;&nbsp;<span className='cn'>{LeftArrow}div</span> <span className='cln'>class="</span>
        <span className='cn'>carve-accordion-body</span><span className='cln'>"</span><span className='cln'>{RightArrow}</span>
        <br />
        &nbsp;&nbsp;&nbsp;<span className='cn'>{LeftArrow}p{RightArrow}</span>&nbsp;
        <span>Fusce nec tellus sed augue semper porta. Mauris massa. Vestibulum lacinia arcu eget nulla.</span>
        <span className='cn'>{LeftArrow}/p{RightArrow}</span>
        <br />
        &nbsp;&nbsp;<span className='cn'>{LeftArrow}/div{RightArrow}</span> 
        <br />
        &nbsp;<span className='cn'>{LeftArrow}/div{RightArrow}</span>
        {/* accordion 3 */}
        <br />
      {/* accordion 4 */}
      &nbsp;<span className='cn'>{LeftArrow}div</span> <span className='cln'>class="</span>
        <span className='cn'>carve-accordion</span><span className='cln'>"</span><span className='cln'>{RightArrow}</span>
        <br />
        &nbsp;&nbsp;<span className='cn'>{LeftArrow}div</span> <span className='cln'>class="</span>
        <span className='cn'>carve-accordion-head</span><span className='cln'>"</span><span className='cln'>{RightArrow}</span>
        <br />
        &nbsp;&nbsp;&nbsp;<span className='cn'>{LeftArrow}div</span> <span className='cln'>class="</span>
        <span className='cn'>heading-small outer-space-0</span><span className='cln'>"</span><span className='cln'>{RightArrow}</span>
         Accordion 4<span className='cn'>{LeftArrow}/div{RightArrow}</span>
        <br />
        &nbsp;&nbsp;<span className='cn'>{LeftArrow}/div{RightArrow}</span>
        <br />
        &nbsp;&nbsp;<span className='cn'>{LeftArrow}div</span> <span className='cln'>class="</span>
        <span className='cn'>carve-accordion-body</span><span className='cln'>"</span><span className='cln'>{RightArrow}</span>
        <br />
        &nbsp;&nbsp;&nbsp;<span className='cn'>{LeftArrow}p{RightArrow}</span>&nbsp;
        <span>Nostra, per inceptos himenaeos. Curabitur sodales ligula in libero.</span>
        <span className='cn'>{LeftArrow}/p{RightArrow}</span>
        <br />
        &nbsp;&nbsp;<span className='cn'>{LeftArrow}/div{RightArrow}</span> 
        <br />
        &nbsp;<span className='cn'>{LeftArrow}/div{RightArrow}</span>
        {/* accordion 4 */}
        <br />
        <span className='cn'>{LeftArrow}/div{RightArrow}</span>
      </code>
    </pre>
  </div>

);

AccordSnippet.propTypes = {};

AccordSnippet.defaultProps = {};

export default AccordSnippet;
