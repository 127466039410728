import React from 'react';
import { motion } from "framer-motion"
import './Card.css';

const Card = ({ CardTitle, CardBody, CardFooter }) => (
  <motion.div
    initial={{ y: 10, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    exit={{ y: -10, opacity: 0 }}
    transition={{ duration: 0.2 }}
  >
   <div className="Card">
      <div className="carve-card">
        <div className="carve-card-head">
          <div className="heading-medium">{CardTitle}</div>
        </div>
        <div className="carve-card-body">
          {CardBody}
        </div>
        <div className="carve-card-footer">
          {CardFooter}
        </div>
      </div>
    </div>
  </motion.div>
);

Card.propTypes = {};

Card.defaultProps = {};

export default Card;
