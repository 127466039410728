import React, { useRef, useState } from 'react';
import { motion } from 'framer-motion';
import './Width.css';
import Card from '../Card/Card';


const WidthSnippet = () => {
  const widthSnippetRef = useRef(null);
  const [showScanning, setShowScanning] = useState(false);

  const wCopySnippet = () => {
    if (widthSnippetRef.current) {
      const range = document.createRange();
      range.selectNode(widthSnippetRef.current);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();

      setShowScanning(true);
      setTimeout(() => setShowScanning(false), 1000);
    }
  };
  const hSnippetRef = useRef(null);
  const [hShowScanning, hSetShowScanning] = useState(false);

  const hCopySnippet = () => {
    if (hSnippetRef.current) {
      const range = document.createRange();
      range.selectNode(hSnippetRef.current);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();

      hSetShowScanning(true);
      setTimeout(() => hSetShowScanning(false), 1000);
    }
  };

  return (
    <>
      <pre>
        <div className={`center-container ${showScanning ? 'scanning' : ''}`}>
          <div className='snippet' ref={widthSnippetRef}>
            <code>
              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-width-quarter<span className='cln'>"</span><span className='cn'>&gt;</span>
              width: 25%;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>
              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-width-half<span className='cln'>"</span><span className='cn'>&gt;</span>
              width: 50%;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>
              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-width-full<span className='cln'>"</span><span className='cn'>&gt;</span>
              width: 100%;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>
              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-width-auto<span className='cln'>"</span><span className='cn'>&gt;</span>
              width: auto;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>
            </code>
          </div>
          <button onClick={wCopySnippet}>
            <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#000000" viewBox="0 0 256 256"><path d="M216,40V168H168V88H88V40Z" opacity="0.2"></path><path d="M216,32H88a8,8,0,0,0-8,8V80H40a8,8,0,0,0-8,8V216a8,8,0,0,0,8,8H168a8,8,0,0,0,8-8V176h40a8,8,0,0,0,8-8V40A8,8,0,0,0,216,32ZM160,208H48V96H160Zm48-48H176V88a8,8,0,0,0-8-8H96V48H208Z"></path></svg>
          </button>
        </div>
        <div className={`center-container ${hShowScanning ? 'scanning' : ''}`}>
          <div className='snippet' ref={hSnippetRef}>
            <code>
              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-height-quarter<span className='cln'>"</span><span className='cn'>&gt;</span>
             height: 25%;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>
              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-height-half<span className='cln'>"</span><span className='cn'>&gt;</span>
             height: 50%;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>
              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-height-full<span className='cln'>"</span><span className='cn'>&gt;</span>
             height: 100%;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>
              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-height-auto<span className='cln'>"</span><span className='cn'>&gt;</span>
             height: auto;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>
            </code>
          </div>
          <button onClick={hCopySnippet}>
            <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#000000" viewBox="0 0 256 256"><path d="M216,40V168H168V88H88V40Z" opacity="0.2"></path><path d="M216,32H88a8,8,0,0,0-8,8V80H40a8,8,0,0,0-8,8V216a8,8,0,0,0,8,8H168a8,8,0,0,0,8-8V176h40a8,8,0,0,0,8-8V40A8,8,0,0,0,216,32ZM160,208H48V96H160Zm48-48H176V88a8,8,0,0,0-8-8H96V48H208Z"></path></svg>
          </button>
        </div>
      </pre>
    </>
  );
}


const Width = () => {

  return (
    <motion.div
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -10, opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <Card
        CardTitle='Width & Height'
        CardBody={<WidthSnippet />}
      />
    </motion.div>
  );
}

Width.propTypes = {};

Width.defaultProps = {};

export default Width;
