import React from 'react';
import './RadioSnippet.css';

const RadioSnippet = () => (
  <pre>
    <code>
      <>
        <span className='cn'>&lt;div</span>
        <span className='cln'> class="</span>
        <span className='cn'>radio-field</span>
        <span className='cln'>"</span>
        <span className='cn'>&gt;</span>
      </>
      <br />
      <>
        &nbsp;<span className='cn'> &lt;input</span>
        <span className='cln'> type="</span>
        <span className='cn'>radio</span>
        <span className='cln'>"</span>
        <span className='cln'> id="</span>
        <span className='cn'>radioOne</span>
        <span className='cln'>"</span>
        <span className='cln'> name="</span>
        <span className='cn'>radioToggle</span>
        <span className='cln'>"</span>
        <span className='cn'>/&gt;</span>
      </>
      <br />
      <>
        &nbsp;<span className='cn'> &lt;label</span>
        <span className='cln'> for="</span>
        <span className='cn'>radioOne</span>
        <span className='cln'>"</span>
        <span className='cn'>&gt;</span>
        <span className=''>radio one</span>
        <span className='cn'>&lt;/label&gt;</span>
      </>
      <br />
      <>
        <span className='cn'>&lt;/div</span>
        <span className='cn'>&gt;</span>
      </>
    </code> <br /> <br />
    <code>
      <>
        <span className='cn'>&lt;div</span>
        <span className='cln'> class="</span>
        <span className='cn'>radio-field</span>
        <span className='cln'>"</span>
        <span className='cn'>&gt;</span>
      </>
      <br />
      <>
        &nbsp;<span className='cn'> &lt;input</span>
        <span className='cln'> type="</span>
        <span className='cn'>radio</span>
        <span className='cln'>"</span>
        <span className='cln'> id="</span>
        <span className='cn'>radioTwo</span>
        <span className='cln'>"</span>
        <span className='cln'> name="</span>
        <span className='cn'>radioToggle</span>
        <span className='cln'>"</span>
        <span className='cn'>/&gt;</span>
      </>
      <br />
      <>
        &nbsp;<span className='cn'> &lt;label</span>
        <span className='cln'> for="</span>
        <span className='cn'>radioTwo</span>
        <span className='cln'>"</span>
        <span className='cn'>&gt;</span>
        <span className=''>radio two</span>
        <span className='cn'>&lt;/label&gt;</span>
      </>
      <br />
      <>
        <span className='cn'>&lt;/div</span>
        <span className='cn'>&gt;</span>
      </>
    </code>
  </pre>

);



export { RadioSnippet };
