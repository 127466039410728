import React, { useRef, useState } from 'react';
import { motion } from 'framer-motion';
import './Text.css';
import Card from '../Card/Card';


const TxtWeightSnipp = () => {
  const TxtWeightSnippRef = useRef(null);
  const [showScanning, setShowScanning] = useState(false);

  const tCopySnippet = () => {
    if (TxtWeightSnippRef.current) {
      const range = document.createRange();
      range.selectNode(TxtWeightSnippRef.current);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();

      setShowScanning(true);
      setTimeout(() => setShowScanning(false), 1000);
    }
  };
  const hSnippetRef = useRef(null);
  const [TxtAlignScan, hSetShowScanning] = useState(false);

  const tWCopySnippet = () => {
    if (hSnippetRef.current) {
      const range = document.createRange();
      range.selectNode(hSnippetRef.current);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();

      hSetShowScanning(true);
      setTimeout(() => hSetShowScanning(false), 1000);
    }
  };

  return (
    <>
      <pre>
        <div className={`center-container ${showScanning ? 'scanning' : ''}`}>
          <div className='snippet' ref={TxtWeightSnippRef}>
            <code>
              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-text-density-tiny<span className='cln'>"</span><span className='cn'>&gt;</span>
              font-weight: 400;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>
              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-text-density-norm<span className='cln'>"</span><span className='cn'>&gt;</span>
              font-weight: 600;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>
              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-text-density-hefty<span className='cln'>"</span><span className='cn'>&gt;</span>
              font-weight: 900;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>

            </code>
          </div>
          <button onClick={tCopySnippet}>
            <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#000000" viewBox="0 0 256 256"><path d="M216,40V168H168V88H88V40Z" opacity="0.2"></path><path d="M216,32H88a8,8,0,0,0-8,8V80H40a8,8,0,0,0-8,8V216a8,8,0,0,0,8,8H168a8,8,0,0,0,8-8V176h40a8,8,0,0,0,8-8V40A8,8,0,0,0,216,32ZM160,208H48V96H160Zm48-48H176V88a8,8,0,0,0-8-8H96V48H208Z"></path></svg>
          </button>
        </div>
        <div className={`center-container ${TxtAlignScan ? 'scanning' : ''}`}>
          <div className='snippet' ref={hSnippetRef}>
            <code>
              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-text-start<span className='cln'>"</span><span className='cn'>&gt;</span>
              text-align: start;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>
              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-text-end<span className='cln'>"</span><span className='cn'>&gt;</span>
              text-align: end;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>
              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-text-middle<span className='cln'>"</span><span className='cn'>&gt;</span>
              text-align: center;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>
              <span className='cn'>&lt;div</span> <span className='cln'> class="</span>carve-text-justify<span className='cln'>"</span><span className='cn'>&gt;</span>
              text-align: justify;
              <span className='cn'>&lt;/div&gt;</span><br></br><br></br>
            </code>
          </div>
          <button onClick={tWCopySnippet}>
            <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#000000" viewBox="0 0 256 256"><path d="M216,40V168H168V88H88V40Z" opacity="0.2"></path><path d="M216,32H88a8,8,0,0,0-8,8V80H40a8,8,0,0,0-8,8V216a8,8,0,0,0,8,8H168a8,8,0,0,0,8-8V176h40a8,8,0,0,0,8-8V40A8,8,0,0,0,216,32ZM160,208H48V96H160Zm48-48H176V88a8,8,0,0,0-8-8H96V48H208Z"></path></svg>
          </button>
        </div>
      </pre>
    </>
  );
}

const Text = () => {

  return (
    <motion.div
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -10, opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <Card
        CardTitle='Text'
        CardBody={<TxtWeightSnipp />}
      />
    </motion.div>
  );
}

Text.propTypes = {};

Text.defaultProps = {};

export default Text;
