import React, { useRef, useState } from 'react';
import { motion } from 'framer-motion';
import './Switch.css';
import Card from './../Card/Card';
import SwitchSnippet from '../../snippets/SwitchSnippet/SwitchSnippet';
const HtmlText = () => <>
  <code className='code'>Note: If you want to remove/modify 'ON/OFF' you can omit/modify the respective html </code>
</>
const Switch = () => {
  const snippetContainerRef = useRef(null);
  const [showScanning, setShowScanning] = useState(false);

  const copySnippet = () => {
    if (snippetContainerRef.current) {
      const range = document.createRange();
      range.selectNode(snippetContainerRef.current);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();

      setShowScanning(true);
      setTimeout(() => setShowScanning(false), 1000);
    }
  };
  return (
    <motion.div
    initial={{ y: 10, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    exit={{ y: -10, opacity: 0 }}
    transition={{ duration: 0.2 }}
    >
   <div className="carve-switch">
    <input type="checkbox" name="toggle-switch" id="toggleSwitch" />
    <label htmlFor="toggleSwitch">
      <span className="on">ON</span>
      <span className="off">OFF</span>
    </label>
  </div>
    <div className={`center-container switch-snippet ${showScanning ? 'scanning' : ''}`}>
      <div className='snippet' ref={snippetContainerRef}>
        <SwitchSnippet />
      </div>
      <button onClick={copySnippet}>
          <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#000000" viewBox="0 0 256 256"><path d="M216,40V168H168V88H88V40Z" opacity="0.2"></path><path d="M216,32H88a8,8,0,0,0-8,8V80H40a8,8,0,0,0-8,8V216a8,8,0,0,0,8,8H168a8,8,0,0,0,8-8V176h40a8,8,0,0,0,8-8V40A8,8,0,0,0,216,32ZM160,208H48V96H160Zm48-48H176V88a8,8,0,0,0-8-8H96V48H208Z"></path></svg>
      </button>

    </div>
  </motion.div>
);
}
const SwitchCard = () => {
  return (
    <Card
      CardTitle='Switch'
      CardBody={<Switch />}
      CardFooter={<HtmlText />}
    />
  );
}
export default SwitchCard;