import React, { useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';
import './Accordion.css';
import AccordSnippet from '../../snippets/AccordSnippet/AccordSnippet';
import Card from './../Card/Card';


const LeftArrow = () => <>&lt;</>;
const RightArrow = () => <>&gt;</>;
const Heading = ({ text }) => <>{text}</>;
const ClassTitle = ({ className }) => <>{className}</>;
const Paragraph = () => <>{Paragraph}</>;
const HtmlText = ({ text }) => <>{text}</>;
const Accordion = ({ title, content, isOpen, toggleAccordion }) => {
  const arrowRotate = isOpen ? 180 : 0;


  return (
    <motion.div
      className="carve-accordion"
      layout
      onClick={toggleAccordion}
      initial={false}
      animate={{ backgroundColor: isOpen ? '#f0f0f0' : 'white' }}
      style={{ borderRadius: '5px', marginBottom: '5px', position: 'relative' }}
    >
      <motion.div className="carve-accordion-head" layout>
        <div className="heading-small outer-space-0">{title}</div>
      </motion.div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            key="content"
            className="carve-accordion-body"

          >
            <p>{content}</p>
          </motion.div>
        )}
      </AnimatePresence>
      <motion.div
        className="carve-accordion-arrow"
        style={{ position: 'absolute', top: '1.5rem', right: '1.5rem', transform: `rotate(${arrowRotate}deg)`, transformOrigin: 'center center', cursor: 'pointer', color: '#94c752' }}
        onClick={toggleAccordion}
      >
        <span className='d-caret'></span>
      </motion.div>
    </motion.div>
  );
};

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleAccordion: PropTypes.func.isRequired,
};

const Accordions = ({ items }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="accordions">
      {items.map((item, index) => (
        <Accordion
          key={index}
          title={item.title}
          content={item.content}
          isOpen={index === openIndex}
          toggleAccordion={() => toggleAccordion(index)}
        />
      ))}
    </div>
  );
};

Accordions.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    })
  ).isRequired,
};

// Example usage:
const accordionItems = [
  {
    title: 'Accordion 1',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero.',
  },
  {
    title: 'Accordion 2',
    content:
      'Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent mauris.',
  },
  {
    title: 'Accordion 3',
    content:
      'Fusce nec tellus sed augue semper porta. Mauris massa. Vestibulum lacinia arcu eget nulla.',
  },
  {
    title: 'Accordion 4',
    content:
      'Nostra, per inceptos himenaeos. Curabitur sodales ligula in libero.',
  },
];

const AnimatedAccordion = () => {
 
  const snippetContainerRef = useRef(null);
  const [showScanning, setShowScanning] = useState(false);

  const copySnippet = () => {
    if (snippetContainerRef.current) {
      const range = document.createRange();
      range.selectNode(snippetContainerRef.current);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();

      setShowScanning(true);
      setTimeout(() => setShowScanning(false), 1000);
    }
  };

  return (
  <motion.div
    initial={{ y: 10, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    exit={{ y: -10, opacity: 0 }}
    transition={{ duration: 0.2 }}
  >
    <Accordions items={accordionItems} />
      <div className={`center-container ${showScanning ? 'scanning' : ''}`}>
        <div className='snippet' ref={snippetContainerRef}>

    <AccordSnippet
      LeftArrow={<LeftArrow />}
      RightArrow={<RightArrow />}
            ClassTitle={<ClassTitle className="carve-accordion" />}
      Heading={<Heading className="heading-medium outer-space-0" text="accordion 1" />}
      Paragraph={<Paragraph text="Lorem, ipsum dolor sit amet consectetur adipiscing elit. Quibusdam, nesciunt nostrum, aliquid officiis vel repellendus explicabo sit dolores sequi eum, quas cupiditate sint in molestiae qui ut quo quae porro?" />}
    />
        </div>
        <button onClick={copySnippet}>
          <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#000000" viewBox="0 0 256 256"><path d="M216,40V168H168V88H88V40Z" opacity="0.2"></path><path d="M216,32H88a8,8,0,0,0-8,8V80H40a8,8,0,0,0-8,8V216a8,8,0,0,0,8,8H168a8,8,0,0,0,8-8V176h40a8,8,0,0,0,8-8V40A8,8,0,0,0,216,32ZM160,208H48V96H160Zm48-48H176V88a8,8,0,0,0-8-8H96V48H208Z"></path></svg>
        </button>
        
      </div>

  </motion.div>
  )
};
const AccordionCard = () => {
  return (
    <Card
      CardTitle={<HtmlText text="Accordions" />}
      CardBody={<AnimatedAccordion />}
    />
  )
}
export default AccordionCard;
