import './App.css';
import './assets/css/carve-global.css'
// import Loader from './components/Loader/Loader';
import Navbar from './components/Navbar/Navbar';
import Main from './pages/Main/Main';
import Bottombar from './components/Bottombar/Bottombar';
import { BrowserRouter as Router } from 'react-router-dom';
function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Main />
        <Bottombar />
        {/* <Loader /> */}
      </div>
    </Router>
  );
}

export default App;
